import { ColorScheme, MantineTheme } from "@mantine/core";
import { createStyles } from "@mantine/styles";

export const getAppStyles = (colorScheme: ColorScheme) => {
	const getthemeStyles = createStyles((theme) => {
		return {};
	});
	const { classes } = getthemeStyles();
	return classes;
};
