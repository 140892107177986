import React from "react";
import { getStyles } from "./styles";
import { useMantineColorScheme } from "@mantine/core";
import { getCostantStyles } from "@/Costants/costantStyles";

const About = () => {
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const classes = getStyles(colorScheme);
	const costantStyles = getCostantStyles(colorScheme);

	return <div className={costantStyles.screen}>About screen</div>;
};

export default About;
