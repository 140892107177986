import React, { useState, useEffect } from "react";
import {
	useMantineColorScheme,
	useMantineTheme,
	ColorScheme,
	Card,
	Title,
	Group,
	Text,
	ScrollArea,
	Divider,
} from "@mantine/core";
import { createStyles } from "@mantine/styles";
import { getCostantStyles } from "@/Costants/costantStyles";
import { decodeBase64 } from "@/Functions/decodeBase64";
import CustomCard from "@/Components/CustomCard";
import monacoThemes from "monaco-themes/themes/themelist.json";
import { getHeightPercent } from "@/Functions/getDimensionsPercent";
import { statusCategoroies, statuses } from "@/Costants/statuses";
import OutputDetails, { OutputDetailsType } from "./OutputDetails";
import UserInput from "./UserInput";
import { requestToApi } from "@/Functions/requestToApi";
import { showErrorToast } from "@/Functions/toastNotifications";
import { AiOutlineCaretDown } from "react-icons/ai";

interface iOutputWindow {
	outputDetails: OutputDetailsType;
	outputWindowHeight: number;
}

const OutputWindow = ({ outputDetails, outputWindowHeight }: iOutputWindow) => {
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const theme = useMantineTheme();
	const classes = getStyles(colorScheme);
	const costantStyles = getCostantStyles(colorScheme);

	const [scrollHeight, setScrollHeight] = useState(getHeightPercent(5));
	const scrollDivRef = React.useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (scrollDivRef.current) {
			if (scrollDivRef.current.offsetHeight < outputWindowHeight)
				setScrollHeight(scrollDivRef.current.offsetHeight);
		}
	}, [outputWindowHeight]);

	const getOutput = () => {
		let outputStatusId = outputDetails?.status?.id;

		const getStringToDisplay = () => {
			if (statusCategoroies.errorStatuses.includes(outputStatusId)) {
				return outputDetails?.errorInfo as string;
			} else if (statusCategoroies.stderrStatuses.includes(outputStatusId)) {
				return outputDetails?.stderr as string;
			}
			return outputDetails?.stdout as string;
		};

		const isError =
			statusCategoroies.errorStatuses.includes(outputStatusId) ||
			statusCategoroies.stderrStatuses.includes(outputStatusId);

		// return (
		// 	<pre className={classes.outputText}>
		// 		{JSON.stringify(monacoThemes, undefined, "    ")}
		// 	</pre>
		// );

		// console.log(getStringToDisplay(), outputDetails);

		return isError ? (
			<pre className={classes.outputTextError}>{getStringToDisplay()}</pre>
		) : (
			<pre className={classes.outputText}>{getStringToDisplay()}</pre>
		);
	};

	return (
		<div
			className={classes.containerDiv}
			style={{
				height: outputWindowHeight * 0.95,
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					height: "10%",
					paddingBottom: "2%",
				}}
			>
				<AiOutlineCaretDown
					size={30}
					color={
						colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.gray[4]
					}
				/>
				<Title className={classes.title} order={2}>
					Terminal
				</Title>
			</div>

			<div
				style={{
					display: "flex",
					flexDirection: "row",
					height: "90%",
					flex: 1,
				}}
				ref={scrollDivRef}
			>
				<Divider
					size="sm"
					orientation="vertical"
					style={{
						height: "100%",
						paddingRight: "2%",
					}}
				/>
				<ScrollArea
					style={{
						height: scrollHeight * 0.9,
						width: "100%",
					}}
				>
					{Object.entries(outputDetails).length != 0 ? (
						<>{getOutput()}</>
					) : null}
				</ScrollArea>
			</div>
		</div>
	);
};

export default OutputWindow;

const getStyles = (colorScheme: ColorScheme) => {
	const getThemeStyles = createStyles((theme) => {
		return {
			containerDiv: {
				display: "flex",
				flexDirection: "column",
				height: "100%",

				flex: 1,
				marginTop: "2%",
				paddingBottom: "1%",

				paddingLeft: "2%",
				paddingRight: "2%",
				backgroundColor:
					colorScheme === "dark" ? theme.colors.gray[9] : theme.colors.gray[3],
			},
			containerCard: {},
			title: {
				// color:
				// 	colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.gray[4],
				marginBottom: "0.5%",
				marginTop: "0%",
				paddingTop: "0%",
			},
			outputText: {
				fontFamily: "monospace",
				// color: theme.colors.gray[4],
			},
			outputTextError: {
				fontFamily: "monospace",
				color: theme.colors.red[4],
			},
		};
	});
	const { classes } = getThemeStyles();
	return classes;
};
