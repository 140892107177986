import React from "react";
import { useMantineColorScheme, ColorScheme } from "@mantine/core";
import { createStyles } from "@mantine/styles";
import { getCostantStyles } from "@/Costants/costantStyles";
import { useTranslation } from "react-i18next";
import TabsIconFactory, { TabsIconFactoryChild } from "./TabsIconFactory";
import { MdDelete } from "react-icons/md";
import {
	AiFillFileAdd,
	AiFillEdit,
	AiOutlineDownload,
	AiOutlineSave,
	AiFillSave,
} from "react-icons/ai";

const TabsSaveFileIcon = ({ forwardRef, onClick }: TabsIconFactoryChild) => {
	const { t } = useTranslation();
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const classes = getStyles(colorScheme);
	const costantStyles = getCostantStyles(colorScheme);

	return (
		<TabsIconFactory
			tooltip="Save file in cache"
			Icon={AiFillSave}
			forwardRef={forwardRef}
			onClick={onClick}
		/>
	);
};

export default TabsSaveFileIcon;

const getStyles = (colorScheme: ColorScheme) => {
	const getThemeStyles = createStyles((theme) => {
		const color =
			colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2];

		return {};
	});
	const { classes } = getThemeStyles();
	return classes;
};
