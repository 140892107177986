// boilerplate components
import { NotificationsProvider } from "@mantine/notifications";
import {
	MantineProvider,
	ColorSchemeProvider,
	Loader,
	Center,
	MantineThemeColorsOverride,
	ColorScheme,
} from "@mantine/core";
import { useHotkeys, useColorScheme } from "@mantine/hooks";
import { BrowserRouter } from "react-router-dom";
import Cookies from "js-cookie";
import { useState } from "react";

interface ProvidersProps {
	children: React.ReactNode;
}

const Providers = (props: ProvidersProps) => {
	// colorScheme logic
	const cookieColorScheme = Cookies.get("colorScheme");
	const preferredColorScheme: ColorScheme = useColorScheme();

	const [colorScheme, setColorScheme] = useState<ColorScheme>(
		(cookieColorScheme as ColorScheme) || preferredColorScheme
	);
	function toggleColorScheme(value?: ColorScheme) {
		value = value || (colorScheme === "dark" ? "light" : "dark");
		Cookies.set("colorScheme", value, {
			expires: 365000,
			sameSite: "lax",
			path: "/",
		});

		setColorScheme(value);

		setTheme({ ...theme, colorScheme: value });
	}
	useHotkeys([["mod+J", () => toggleColorScheme()]]);

	const [theme, setTheme] = useState<MantineThemeColorsOverride>({
		colorScheme,
		colors: {
			//brand colors
			brand: [
				"#F0BBDD",
				"#ED9BCF",
				"#EC7CC3",
				"#ED5DB8",
				"#F13EAF",
				"#F71FA7",
				"#D9480F", //buttons light
				"#E8590C", //buttons hover light
				"#E8590C", //buttons dark
				"#D9480F", //buttons hover dark
			],
		},
		primaryColor: "blue",
	});

	const [isLoading, setIsLoading] = useState(false);

	// https://mantine.dev/theming/mantine-provider/#styles-on-mantineprovider
	// override styles for Mantine components
	const styles = {
		Checkbox: { input: { cursor: "pointer" }, label: { cursor: "pointer" } },
	};

	// default props for Mantine components
	const defaultProps = {
		Loader: { size: "xl" },
	};

	return (
		<ColorSchemeProvider
			colorScheme={colorScheme}
			toggleColorScheme={toggleColorScheme}
		>
			<MantineProvider
				defaultProps={defaultProps}
				theme={theme}
				styles={styles}
				withGlobalStyles
				withNormalizeCSS
				withCSSVariables
			>
				<NotificationsProvider>
					<BrowserRouter>
						{/* show splashscreen for inital data */}
						{isLoading ? (
							<Center style={{ height: "100vh", width: "100vw" }}>
								<Loader size="xl" />
							</Center>
						) : (
							props.children
						)}
					</BrowserRouter>
				</NotificationsProvider>
			</MantineProvider>
		</ColorSchemeProvider>
	);
};

export default Providers;
