import React, { useState, useEffect, useContext } from "react";
import { useMantineColorScheme, ColorScheme, ScrollArea } from "@mantine/core";
import { createStyles } from "@mantine/styles";
import { getCostantStyles } from "@/Costants/costantStyles";
import { useTranslation } from "react-i18next";
import FolderTree, { testData } from "react-folder-tree";
import FolderIcon from "./TreeIcons/FolderIcon";
import FolderOpenIcon from "./TreeIcons/FolderOpenIcon";
import FileIcon from "./TreeIcons/FileIcon";
import { iFile, iFileManager } from "@/Types/FileManager";
import { FileManagerContext } from "@/Components/EditorScreen/FileManagerContext";
import { getFileTree } from "@/tools/FileManagerTools/getFileTree";

export interface iFolderTreeData {
	name: string;
	children?: iFolderTreeData[];
	code?: string;
	fileKey?: string;
	fileManagerPath?: string;
}

interface iFolderTreeProps {
	onFileClick: (file: iFile) => void;
	width?: number;
}

const FileTree = ({ onFileClick, width }: iFolderTreeProps) => {
	const { t } = useTranslation();
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const classes = getStyles(colorScheme);
	const costantStyles = getCostantStyles(colorScheme);

	const { fileManager } = useContext(FileManagerContext);

	const [folderTreeData, setFolderTreeData] = useState<iFolderTreeData>({
		name: "root",
		children: [],
	});

	useEffect(() => {
		setFolderTreeData(getFileTree(fileManager));
	}, [fileManager]);

	const onTreeStateChange = (state: any, event: any) => {};

	const onNameClick = ({ defaultOnClick, nodeData }: any) => {
		defaultOnClick();

		const { path, name, checked, isOpen, ...rest } = nodeData;
		if (rest.hasOwnProperty("code") && rest.hasOwnProperty("fileKey")) {
			onFileClick(fileManager[rest.fileKey]);
		}
	};

	return (
		<div
			className={classes.fileTreeContainer}
			style={{
				width: width ? width : "100%",
			}}
		>
			<FolderTree
				data={folderTreeData}
				onChange={onTreeStateChange}
				showCheckbox={false}
				iconComponents={{
					FolderIcon,
					FolderOpenIcon,
					FileIcon,
				}}
				onNameClick={onNameClick}
				readOnly
			/>
		</div>
	);
};

export default FileTree;

const getStyles = (colorScheme: ColorScheme) => {
	const getThemeStyles = createStyles((theme) => {
		const color =
			colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2];

		return {
			fileTreeContainer: {
				display: "flex",
				flexDirection: "column",
				height: "100%",
				width: "100%",
				// backgroundColor:
				// 	colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2],
				marginRight: "0px",
			},
		};
	});
	const { classes } = getThemeStyles();
	return classes;
};
