import { useContext, useState } from "react";
import { useMantineColorScheme, ColorScheme } from "@mantine/core";
import { createStyles } from "@mantine/styles";
import { getCostantStyles } from "@/Costants/costantStyles";
import { useTranslation } from "react-i18next";
import { iFile } from "@/Types/FileManager";
import { FileManagerContext } from "@/Components/EditorScreen/FileManagerContext";
import ThemeDropdown from "@/Components/ThemeDropdown";
import { EditorSettingsContext } from "../../../../EditorSettingsContext";

interface iSettingsProps {
	// handleThemeChange: (theme: string) => void;
}

const Settings = ({}: iSettingsProps) => {
	const { t } = useTranslation();
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const classes = getStyles(colorScheme);
	const costantStyles = getCostantStyles(colorScheme);

	//get settings context
	const { editorSettings, editorSettingsTools } = useContext(
		EditorSettingsContext
	);

	const [theme, setTheme] = useState<{ label: string; value: string }>({
		label: editorSettings.theme,
		value: editorSettings.theme,
	});

	return (
		<div className={classes.settingsContainer}>
			<ThemeDropdown
				handleThemeChange={(theme) => {
					setTheme({
						label: theme,
						value: theme,
					});
					editorSettingsTools.handleThemeChange(theme);
				}}
				theme={theme.value}
			/>
		</div>
	);
};

export default Settings;

const getStyles = (colorScheme: ColorScheme) => {
	const getThemeStyles = createStyles((theme) => {
		const color =
			colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2];

		return {
			settingsContainer: {
				display: "flex",
				flexDirection: "column",
				height: "100%",
				width: "100%",
				// backgroundColor:
				// 	colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2],
				marginRight: "0px",
				paddingRight: "2%",
				paddingLeft: "2%",
			},
		};
	});
	const { classes } = getThemeStyles();
	return classes;
};
