//! nginx prefix
const prefix = "http://localhost:4000/";
export const apiKey = "test";

const apiEndpoints = {
	apiKey,
	prefix,
	languagesData: `${prefix}apiInfo/languagesData`,

	// @deprecated
	// run: `${prefix}api/run`,
	// input: `${prefix}api/input`,
};

export default apiEndpoints;
