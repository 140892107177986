import { AiFillFile } from "react-icons/ai";
import GetCodeFileIcon from "@/Components/GetCodeFileIcon";

interface iFileIcon {
	onClick: () => void;
	nodeData: any;
}

const FileIcon = ({ onClick: defaultOnClick, nodeData }: iFileIcon) => {
	const { path, name, checked, isOpen, ...restData } = nodeData;
	const extension = name.split(".").pop();

	const handleClick = () => {
		defaultOnClick();
		// console.log("click");
	};

	// custom Style
	return (
		<GetCodeFileIcon
			fileExtension={extension}
			size={15}
			onClick={handleClick}
			style={{
				marginRight: "2px",
			}}
		/>
	);
};

export default FileIcon;
