import { apiKey } from "@/Costants/apiEndpoints";
import axios from "axios";
import { ApiResponse } from "../Types/ApiResponse";
export const requestToApi = async (
	url: string,
	method: "get" | "post" = "get",
	body?: any
): Promise<ApiResponse> => {
	const apiUrl = new URL(url);
	apiUrl.searchParams.append("apiKey", apiKey);

	const options = {
		url: apiUrl.href,
		method,
		data: body ? body : undefined,
	};

	try {
		const res = await axios(options);
		return {
			errorHappened: false,
			...res,
		};
	} catch (error) {
		return {
			errorHappened: true,
			errorInfo: error,
		};
	}
};
