import { AiFillFolder } from "react-icons/ai";
import { IconType } from "react-icons";

interface iIconFactory {
	onClick: () => void;
	nodeData: any;
	Icon: IconType;
}

const IconFactory = ({
	onClick: defaultOnClick,
	nodeData,
	Icon,
}: iIconFactory) => {
	const { path, name, checked, isOpen, ...restData } = nodeData;

	const handleClick = () => {
		defaultOnClick();
	};

	// custom Style
	return <Icon onClick={handleClick} />;
};

export default IconFactory;
