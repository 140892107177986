import React, { useEffect, useState, useImperativeHandle } from "react";
import CodeEditorWindow from "./Components/CodeEditorWindow";
import { languageOptions } from "@/Costants/languageOptions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { defineTheme } from "@/lib/defineTheme";
import useKeyPress from "@/hooks/useKeyPress";
import { getStyles } from "./styles";
import { useMantineColorScheme } from "@mantine/core";
import { getCostantStyles } from "@/Costants/costantStyles";
import { encodeBase64 } from "@/Functions/encodeBase64";
import { OutputDetailsType } from "./Components/EditorTerminal/OutputWindow/OutputDetails";
import {
	showSuccessToast,
	showErrorToast,
} from "@/Functions/toastNotifications";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import wbEndpoints from "../../Costants/wsEndpoints";
import { wsStatuses } from "@/Costants/wsStatuses";
import { statuses, statusCategoroies } from "../../Costants/statuses";
import { sendToWs } from "@/WebSocketClient/sendToWs";
import { showWarningToast } from "../../Functions/toastNotifications";
import { iFile, iFileManager } from "../../Types/FileManager";
import { FileManagerContext } from "@/Components/EditorScreen/FileManagerContext";
import { EditorTabsHandle } from "./Components/EditorTabs";
import Sidebar from "./Components/Sidebar";
import { NumberSize, Resizable } from "re-resizable";
import {
	getHeightPercent,
	getWidthPercent,
} from "../../Functions/getDimensionsPercent";
import { Direction } from "re-resizable/lib/resizer";
import { forwardRef } from "react";
import { EditorSettingsContext } from "./EditorSettingsContext";
import { cookiesPaths } from "../../Costants/cookiesPaths";
import EditorTerminal from "./Components/EditorTerminal/index";

interface iEditorScreen {
	fileManager: iFileManager;
	setFileManager: (newFileManager: iFileManager) => void;
}
export interface EditorScreenHandle {
	openFile: (fileKey: string) => void;
}
const SIDEBAR_ICONS_SIZE = 40;
const SIDEBAR_MIN_OPEN_WIDTH = 250;

const EditorScreen = forwardRef<EditorScreenHandle, iEditorScreen>(
	({ fileManager, setFileManager }, ref) => {
		const { t } = useTranslation();
		const { colorScheme, toggleColorScheme } = useMantineColorScheme();
		const classes = getStyles(colorScheme);
		const costantStyles = getCostantStyles(colorScheme);

		const [outputDetails, setOutputDetails] = useState<OutputDetailsType | {}>(
			{}
		);
		const [processing, setProcessing] = useState<boolean>(false);
		const [isProgramExecuting, setIsProgramExecuting] = useState(false);
		const [webSocket, setWebSocket] = useState<WebSocket | null>(null);

		const savedTheme = Cookies.get(cookiesPaths.editorTheme) || "vs-dark";
		const [theme, setTheme] = useState<{ label: string; value: string }>({
			label: savedTheme,
			value: savedTheme,
		});

		const [screenDimensions, setScreenDimensions] = useState({
			width: getWidthPercent(94),
			height: getHeightPercent(100),
		});
		const [componentsDimensions, setComponentsDimensions] = useState({
			sidebar: {
				width: SIDEBAR_MIN_OPEN_WIDTH, // SIDEBAR_ICONS_SIZE + 10,
				height: "100%",
			},
			output: {
				width: getWidthPercent(20) > 500 ? 500 : getWidthPercent(20),
				height: "100%",
			},
		});
		const [activeFileKey, setActiveFileKey] = useState(
			Object.values(fileManager)[0].fileKey
		);

		//! ref
		const tabsRef = React.useRef<EditorTabsHandle>(null);
		useImperativeHandle(ref, () => ({
			openFile: (fileKey: string) => {
				setActiveFileKey(fileKey);
			},
		}));

		const enterPress = useKeyPress("Enter");
		const ctrlPress = useKeyPress("Control");

		useEffect(() => {
			defineTheme(theme.value);
			const handleScreenChange = () => {
				setScreenDimensions({
					width: getWidthPercent(94),
					height: getHeightPercent(100),
				});
			};
			window.addEventListener("resize", handleScreenChange);
			return () => {
				window.removeEventListener("resize", handleScreenChange);
			};
		}, []);
		useEffect(() => {
			if (enterPress && ctrlPress && !processing) {
				handleCompile();
			}
		}, [ctrlPress, enterPress]);

		const onCodeChange = (newCode: string) => {
			const newFileManager = { ...fileManager };
			newFileManager[activeFileKey].code = newCode;
			setFileManager(newFileManager);
		};

		useEffect(() => {
			if (!webSocket) return;

			const messageListener = (event: MessageEvent) => {
				const {
					errorHappened: wsErrorHappened,
					errorInfo: wsErrorInfo,
					status,
					...rest
				} = JSON.parse(event.data as string);

				const newData = {
					...rest,
					status,
				};
				if (wsErrorHappened) {
					newData.errorHappened = wsErrorHappened;
					newData.errorInfo = wsErrorInfo;
				}

				setOutputDetails((prev) => ({
					...prev,
					...newData,
				}));

				if (
					statusCategoroies.errorStatuses.includes(status.id) ||
					statusCategoroies.stderrStatuses.includes(status.id)
				) {
					showErrorToast(status.description);
				} else if (
					status.id === statuses["Completed successfully"].id ||
					status.id === statuses["Process Killed Successfully"].id
				) {
					setIsProgramExecuting(false);
					showSuccessToast(status.description);
				}

				// else if (status.id == statuses["Waiting"].id) {
				// 	showWarningToast("Waiting.for input");
				// }
				setProcessing(false);
			};

			webSocket.addEventListener("message", messageListener);

			return () => {
				webSocket.removeEventListener("message", messageListener);
			};
		}, [webSocket]);

		const handleCompile = async (fileKey?: string) => {
			if (fileKey == undefined) fileKey = activeFileKey;

			let ws = webSocket || new WebSocket(wbEndpoints.run);
			if (!webSocket) {
				setWebSocket(ws);
			}
			const data = JSON.stringify({
				fileName: fileManager[fileKey].name,
				code: encodeBase64(fileManager[fileKey].code),
				langId: fileManager[fileKey].langId,
				wsStatus: wsStatuses[1],
			});

			Cookies.set(
				cookiesPaths.savedFile,
				JSON.stringify(fileManager[fileKey]),
				{
					expires: 365000,
					sameSite: "lax",
					path: "/",
				}
			);

			setProcessing(true);

			const handleServerOffline = setTimeout(() => {
				showErrorToast("Server is offline, please try again later");
				setProcessing(false);
			}, 15000);

			if (ws.readyState === WebSocket.OPEN) {
				ws.send(data);
				showWarningToast("Executing...");
				setIsProgramExecuting(true);
				clearTimeout(handleServerOffline);
			} else {
				ws.onopen = () => {
					ws.send(data);
					showWarningToast("Executing...");
					setIsProgramExecuting(true);
					clearTimeout(handleServerOffline);
				};
			}
		};

		const killProcess = () => {
			sendToWs(webSocket as WebSocket, {
				wsStatus: wsStatuses[444],
				processId: (outputDetails as OutputDetailsType).processId,
			});
			// showSuccessToast("Process killed successfully");

			// setIsProgramExecuting(false);
			// setProcessing(false);
		};

		const handleInputSend = async (input: string) => {
			setProcessing(true);

			const sendRes = sendToWs(webSocket as WebSocket, {
				input,
				processId: (outputDetails as OutputDetailsType).processId,
				wsStatus: wsStatuses[2],
			});
			if (!sendRes) {
				setProcessing(false);
				showErrorToast("Server is offline, please try again later");
			}
		};

		const handleThemeChange = (th: string) => {
			if (th === theme.value) return;
			if (["light", "vs-dark"].includes(th)) {
				setTheme({ label: th, value: th });
			} else {
				defineTheme(th).then((_) => setTheme({ label: th, value: th }));
			}
			Cookies.set(cookiesPaths.savedFile, th, {
				expires: 365000,
				sameSite: "lax",
				path: "/",
			});
		};

		const handleFileTreeClick = (file: iFile) => {
			if (tabsRef.current) {
				tabsRef.current.openFile(file);
			}
		};

		const editFile = (
			fileKey: string,
			newName?: string,
			newPath?: string,
			newLangId?: number
		) => {
			if (
				newName == undefined &&
				newPath == undefined &&
				newLangId == undefined
			)
				return;
			const newFileManager = { ...fileManager };
			const oldFile = fileManager[fileKey];
			const newFile: iFile = {
				...oldFile,
				name: newName || oldFile.name,
				path: newPath || oldFile.path,
				langId: newLangId != undefined ? newLangId : oldFile.langId,
				fileExtension:
					newLangId != undefined
						? languageOptions[newLangId].fileExtension
						: oldFile.fileExtension,
			};
			newFileManager[fileKey] = newFile;

			setFileManager(newFileManager);
		};

		const onResizeStop = (
			event: MouseEvent | TouchEvent,
			direction: Direction,
			elementRef: HTMLElement,
			delta: NumberSize,
			component: "sidebar" | "output"
		) => {
			setComponentsDimensions({
				...componentsDimensions,
				[component]: {
					width: componentsDimensions[component].width + delta.width,
					height: "100%",
				},
			});
		};

		return (
			<EditorSettingsContext.Provider
				value={{
					editorSettings: {
						theme: theme.value,
					},
					editorSettingsTools: {
						handleThemeChange,
					},
				}}
			>
				<FileManagerContext.Provider
					value={{
						fileManager,
						isProgramExecuting,
						fileManagerTools: {
							addFile: (file: iFile) => {
								const newFileManager = { ...fileManager };
								newFileManager[file.fileKey] = file;
								setFileManager(newFileManager);
								setActiveFileKey(file.fileKey);
								tabsRef.current?.openFile(file);
							},
							editFile,
							deleteFile: (fileKey: string) => {
								const newFileManager = { ...fileManager };
								delete newFileManager[fileKey];
								setFileManager(newFileManager);
							},
							compileFile: handleCompile,
							handleInputSend,
							killProcess,
						},
					}}
				>
					<div className={costantStyles.screen}>
						<ToastContainer
							position="top-right"
							autoClose={2000}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
						/>
						<div className={classes.editorContainerDiv}>
							<Resizable
								defaultSize={componentsDimensions.sidebar}
								size={componentsDimensions.sidebar}
								onResizeStop={(e, direction, ref, d) =>
									onResizeStop(e, direction, ref, d, "sidebar")
								}
								minWidth={SIDEBAR_ICONS_SIZE}
							>
								<Sidebar
									handleFileTreeClick={handleFileTreeClick}
									handleToggle={(open) => {
										if (open) {
											setComponentsDimensions({
												...componentsDimensions,
												sidebar: {
													width: SIDEBAR_MIN_OPEN_WIDTH,
													height: "100%",
												},
											});
											return;
										}
										setComponentsDimensions({
											...componentsDimensions,
											sidebar: {
												width: SIDEBAR_ICONS_SIZE,
												height: "100%",
											},
										});
									}}
									currentWidth={componentsDimensions.sidebar.width}
								/>
							</Resizable>
							<div
								style={{
									flex: 1,
								}}
							>
								<CodeEditorWindow
									fileKey={activeFileKey}
									onChange={onCodeChange}
									theme={theme.value}
									tabsRef={tabsRef}
									onFileChange={(newFileKey) => {
										if (newFileKey === activeFileKey) return;
										if (newFileKey !== null) {
											setActiveFileKey(newFileKey);
										}
									}}
									// Terminal
									terminalProps={{
										activeFileKey,
										outputDetails: outputDetails as OutputDetailsType,
										isProcessing: processing,
									}}
								/>
							</div>
						</div>
					</div>
				</FileManagerContext.Provider>
			</EditorSettingsContext.Provider>
		);
	}
);
export default EditorScreen;
