import React from "react";
import { languagesIcons } from "../Costants/languagesIcons";

interface iGetCodeFileIcon {
	fileExtension: string;
	size?: number;
	[key: string]: any;
}

const GetCodeFileIcon = ({
	fileExtension,
	size,
	...rest
}: iGetCodeFileIcon) => {
	const Icon = languagesIcons.hasOwnProperty(fileExtension)
		? languagesIcons[fileExtension]
		: languagesIcons.default;
	return <Icon size={size ? size : 20} {...rest} />;
};

export default GetCodeFileIcon;
