import React, { useState } from "react";
import {
	useMantineColorScheme,
	ColorScheme,
	Divider,
	Title,
} from "@mantine/core";
import { createStyles } from "@mantine/styles";
import { getCostantStyles } from "@/Costants/costantStyles";
import { useTranslation } from "react-i18next";
import { MdOutlineAccountTree } from "react-icons/md";
import SidebarIcon from "./SidebarIcon";
import FileTree from "./SidebarComponents/FileTree";
import { iFile } from "@/Types/FileManager";
import { getWidthPercent } from "@/Functions/getDimensionsPercent";
import { IoSettingsOutline } from "react-icons/io5";
import Settings from "./SidebarComponents/Settings";

interface iSidebar {
	handleFileTreeClick: (file: iFile) => void;
	handleToggle: (open: boolean) => void;
	currentWidth: number;
}
type DisplayStatus = "none" | "fileTree" | "settings";

const ICONS_SIZE = 40;
//resizable https://github.com/bokuweb/re-resizable#usage
const Sidebar = ({
	handleFileTreeClick,
	handleToggle,
	currentWidth,
}: iSidebar) => {
	const { t } = useTranslation();
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const classes = getStyles(colorScheme);
	const costantStyles = getCostantStyles(colorScheme);

	const [displayStatus, setDisplayStatus] = useState<DisplayStatus>("fileTree");

	const defaultWidth = ICONS_SIZE + 10;
	const minOpenWidth = 250;
	const defaultWidthOpen =
		getWidthPercent(15) > minOpenWidth ? getWidthPercent(15) : minOpenWidth;

	const handleDisplayStatusChange = (status: DisplayStatus) => {
		if (displayStatus === status) {
			setDisplayStatus("none");
			handleToggle(false);
			return;
		}
		handleToggle(true);
		setDisplayStatus(status);
	};

	const getDisplayItem = () => {
		const itemFactory = (label: string, Item: any) => {
			return (
				<>
					<Title className={classes.displayDivHeader} order={3}>
						{label}
					</Title>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							height: "100%",
							justifyContent: "space-between",
						}}
					>
						{Item}
						<Divider
							size="sm"
							orientation="vertical"
							style={{
								height: "100%",
								paddingRight: "0.5%",
							}}
						/>
					</div>
				</>
			);
		};

		switch (displayStatus) {
			case "fileTree":
				return itemFactory(
					"File Explorer",
					<FileTree
						onFileClick={handleFileTreeClick}
						width={currentWidth - defaultWidth}
					/>
				);
			case "settings":
				return itemFactory("Settings", <Settings />);
			default:
				return null;
		}
	};

	return (
		<div className={classes.container}>
			<div className={classes.iconsContainer}>
				<SidebarIcon
					Icon={MdOutlineAccountTree}
					onClick={() => handleDisplayStatusChange("fileTree")}
					label={"File tree"}
					isActive={displayStatus === "fileTree"}
				/>
				<SidebarIcon
					Icon={IoSettingsOutline}
					onClick={() => handleDisplayStatusChange("settings")}
					label={"Settings"}
					isActive={displayStatus === "settings"}
				/>
			</div>
			{displayStatus != "none" && (
				<div className={classes.displayDiv}>{getDisplayItem()}</div>
			)}
		</div>
	);
};

export default Sidebar;

const getStyles = (colorScheme: ColorScheme) => {
	const getThemeStyles = createStyles((theme) => {
		const backgroundColor =
			colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.blue[1];
		return {
			container: {
				display: "flex",
				flexDirection: "row",
				height: "100%",
				// paddingRight: "0.2%",
				paddingLeft: "0.2%",
				paddingTop: "0.2%",
			},
			iconsContainer: {
				display: "flex",
				flexDirection: "column",
				height: "100%",
				width: `${ICONS_SIZE}px`,
				paddingRight: "0.2%",
				paddingLeft: "0.2%",
				paddingTop: "0.2%",
				backgroundColor,
			},
			displayDiv: {
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				flex: 1,
			},
			displayDivHeader: {
				backgroundColor,
				width: "99%",
				paddingLeft: "1%",
			},
		};
	});
	const { classes } = getThemeStyles();
	return classes;
};
