import React, {
	forwardRef,
	useContext,
	useImperativeHandle,
	useState,
} from "react";
import { useMantineColorScheme, ColorScheme, Modal } from "@mantine/core";
import { createStyles } from "@mantine/styles";
import { getCostantStyles } from "@/Costants/costantStyles";
import { useTranslation } from "react-i18next";
import { TemplateModalHandle } from "./TemplateModal";
import NewFileForm, { iNewFileForm } from "../CodeEditorWindow/NewFileForm";
import { FileManagerContext } from "@/Components/EditorScreen/FileManagerContext";
import { languageOptions } from "@/Costants/languageOptions";
import { getForbiddenNames } from "@/tools/FileManagerTools/getForbiddenNames";
import { v4 as uuidv4 } from "uuid";
import { iJavaLanguage } from "../../../../Costants/languageOptions";

interface iNewFileFormModal {
	defaultPath?: string;
}
export interface NewFileFormModalHandle extends TemplateModalHandle {}

const NewFileFormModal = forwardRef<NewFileFormModalHandle, iNewFileFormModal>(
	({ defaultPath }, ref) => {
		const { t } = useTranslation();
		const { colorScheme, toggleColorScheme } = useMantineColorScheme();
		const classes = getStyles(colorScheme);
		const costantStyles = getCostantStyles(colorScheme);

		const { fileManager, fileManagerTools } = useContext(FileManagerContext);

		const [modalOpened, setModalOpened] = useState(false);

		useImperativeHandle(ref, () => ({
			open: () => setModalOpened(true),
			close: () => setModalOpened(false),
			getModalState: () => modalOpened,
		}));

		const onFileCreatePress = (
			fileName: string,
			langId: number,
			folder: string
		) => {
			const language = languageOptions[langId];
			let fileKey = uuidv4();
			let i = 0;
			while (Object.keys(fileManager).includes(fileKey)) {
				if (i >= 20) {
					alert(
						"An error occurend when creating the file, could not generate unique file key."
					);
					return;
				}
				fileKey = uuidv4();
				i++;
			}
			const code =
				langId != 2
					? language.defaultCode
					: (language as iJavaLanguage).getDefaultCode(fileName);
			fileManagerTools.addFile({
				name: fileName,
				fileExtension: language.fileExtension,
				langId,
				path: `${folder}/${fileName}`,
				code,
				fileKey,
			});
			setModalOpened(false);
		};

		return (
			<Modal
				opened={modalOpened}
				onClose={() => setModalOpened(false)}
				title="New file"
			>
				<NewFileForm
					forbiddenNames={getForbiddenNames(fileManager)}
					onCreatePress={onFileCreatePress}
					defaultPath={defaultPath}
				/>
			</Modal>
		);
	}
);

export default NewFileFormModal;

const getStyles = (colorScheme: ColorScheme) => {
	const getThemeStyles = createStyles((theme) => {
		const color =
			colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2];

		return {};
	});
	const { classes } = getThemeStyles();
	return classes;
};
