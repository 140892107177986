import React, {
	forwardRef,
	useImperativeHandle,
	useState,
	useContext,
} from "react";
import {
	useMantineColorScheme,
	ColorScheme,
	Modal,
	Text,
	Title,
	Button,
} from "@mantine/core";
import { createStyles } from "@mantine/styles";
import { getCostantStyles } from "@/Costants/costantStyles";
import { useTranslation } from "react-i18next";
import { FileManagerContext } from "@/Components/EditorScreen/FileManagerContext";

interface iTemplateModal {
	activeFileKey: string;
	onFileDelete: (path: string) => void;
}
export interface TemplateModalHandle {
	open: () => void;
	close: () => void;
	getModalState: () => boolean;
}

const DeleteFileModal = forwardRef<TemplateModalHandle, iTemplateModal>(
	({ activeFileKey, onFileDelete }, ref) => {
		const { t } = useTranslation();
		const { colorScheme, toggleColorScheme } = useMantineColorScheme();
		const classes = getStyles(colorScheme);
		const costantStyles = getCostantStyles(colorScheme);
		const [modalOpened, setModalOpened] = useState(false);

		//get file manager
		const { fileManager } = useContext(FileManagerContext);
		const file = fileManager[activeFileKey];

		useImperativeHandle(ref, () => ({
			open: () => setModalOpened(true),
			close: () => setModalOpened(false),
			getModalState: () => modalOpened,
		}));

		return (
			<Modal
				opened={modalOpened}
				onClose={() => setModalOpened(false)}
				title="Delete selected file"
			>
				<Title order={4}>Are you sure you want to delete this file?</Title>
				<div className={classes.fileInfo}>
					<Text>
						<b>name:</b> {file?.name}.{file?.fileExtension}
					</Text>
					<Text>
						<b>path:</b> {file?.path}
					</Text>
				</div>
				<Text color="red" weight={700}>
					THIS ACTION IS IRREVERSIBLE!
				</Text>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						paddingTop: "2%",
					}}
				>
					<Button
						size="lg"
						color={"red"}
						onClick={() => {
							onFileDelete(activeFileKey);
							setModalOpened(false);
						}}
					>
						Delete file
					</Button>
				</div>
			</Modal>
		);
	}
);

export default DeleteFileModal;

const getStyles = (colorScheme: ColorScheme) => {
	const getThemeStyles = createStyles((theme) => {
		const color =
			colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2];

		return {
			fileInfo: {
				display: "flex",
				flexDirection: "column",
			},
		};
	});
	const { classes } = getThemeStyles();
	return classes;
};
