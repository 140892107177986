import React, { useState, useEffect, useRef } from "react";
import {
	useMantineColorScheme,
	ColorScheme,
	Tabs,
	ActionIcon,
} from "@mantine/core";
import { createStyles } from "@mantine/styles";
import { getCostantStyles } from "@/Costants/costantStyles";
import { useTranslation } from "react-i18next";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";
import { IoMdClose } from "react-icons/io";
import specialFileKeys from "@/Costants/specialFileKeys";

interface iDraggableTab {
	index: number;
	fileKey: string;
	label: string;
	icon: JSX.Element;
	tabsLength: number;
	moveTab: (dragIndex: number, hoverIndex: number) => void;
	onTabChange: (tabIndex: number) => void;
	onTabClose: (tabIndex: number) => void;
}
// ? source https://blog.logrocket.com/drag-and-drop-react-dnd/#react-beautiful-dnd
const DraggableTab = ({
	index,
	fileKey,
	label,
	icon,
	moveTab,
	tabsLength,
	onTabChange,
	onTabClose,
}: iDraggableTab) => {
	const { t } = useTranslation();
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const classes = getStyles(colorScheme, index, tabsLength);
	const costantStyles = getCostantStyles(colorScheme);

	const type = "DraggableTab";
	const ref = useRef(null); // Initialize the reference
	const [, drop] = useDrop({
		accept: type,
		hover(item: { index: number }) {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;
			if (dragIndex === hoverIndex) {
				return;
			}
			moveTab(dragIndex, hoverIndex);
			item.index = hoverIndex;
		},
		// drop(item: { index: number }) {
		// 	if (!ref.current) {
		// 		return;
		// 	}
		// 	const dragIndex = item.index;
		// 	const hoverIndex = index;
		// 	if (dragIndex === hoverIndex) {
		// 		return;
		// 	}
		// 	moveTab(dragIndex, hoverIndex);
		// 	item.index = hoverIndex;
		// },
	});
	const [{ isDragging }, drag] = useDrag(() => ({
		type: type,
		item: { index },
		collect: (monitor) => {
			return {
				isDragging: monitor.isDragging(),
			};
		},
	}));

	drag(drop(ref));

	return (
		<div className={classes.tabContainer}>
			<Tabs.Tab
				ref={ref}
				key={index}
				label={label}
				icon={icon}
				onClick={() => onTabChange(index)}
				style={{
					paddingRight: tabsLength > 1 ? "5px" : undefined,
					paddingLeft: "10px",
				}}
			/>
			{fileKey == specialFileKeys.welcomeFileKey && tabsLength != 1 && (
				<ActionIcon
					onClick={() => {
						onTabClose(index);
					}}
				>
					<IoMdClose size={25} />
				</ActionIcon>
			)}
			{fileKey != specialFileKeys.welcomeFileKey && (
				<ActionIcon
					onClick={() => {
						onTabClose(index);
					}}
				>
					<IoMdClose size={25} />
				</ActionIcon>
			)}
		</div>
	);
};

export default DraggableTab;

const getStyles = (
	colorScheme: ColorScheme,
	index: number,
	tabsLength: number
) => {
	const getThemeStyles = createStyles((theme) => {
		const borderRadius = 8;
		const borderWidth = 1;

		const hasTabRight = index != tabsLength - 1;
		const hasTabLeft = index != 0;

		return {
			tabContainer: {
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				borderStyle: "solid",
				borderColor:
					colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.dark[2],
				borderWidth: `${borderWidth}px ${
					hasTabRight ? 0 : borderWidth
				}px 0 ${borderWidth}px`,
				borderRadius: `${hasTabLeft ? 0 : borderRadius}px ${
					hasTabRight ? 0 : borderRadius
				}px 0px 0px`,
			},
		};
	});
	const { classes } = getThemeStyles();
	return classes;
};
