import React, { useContext } from "react";
import {
	useMantineColorScheme,
	ColorScheme,
	Dialog,
	Text,
	Group,
	Button,
	TextInput,
} from "@mantine/core";
import { createStyles } from "@mantine/styles";
import { getCostantStyles } from "@/Costants/costantStyles";
import { useTranslation } from "react-i18next";
import KillProcessIcon from "./KillProcessIcon";
import { FileManagerContext } from "@/Components/EditorScreen/FileManagerContext";

interface iExecutionDialog {
	isOpen: boolean;
	position?: {
		top?: string | number;
		left?: string | number;
		bottom?: string | number;
		right?: string | number;
	};
}

const ExecutionDialog = ({ isOpen, position }: iExecutionDialog) => {
	const { t } = useTranslation();
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const classes = getStyles(colorScheme);
	const costantStyles = getCostantStyles(colorScheme);

	const { fileManagerTools } = useContext(FileManagerContext);

	return (
		<Dialog opened={isOpen} size="lg" radius="md" position={position}>
			<Text size="lg" style={{ marginBottom: 10 }} weight={500}>
				Execution tools
			</Text>
			<KillProcessIcon onClick={fileManagerTools.killProcess} />
		</Dialog>
	);
};

export default ExecutionDialog;

const getStyles = (colorScheme: ColorScheme) => {
	const getThemeStyles = createStyles((theme) => {
		const color =
			colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2];

		return {};
	});
	const { classes } = getThemeStyles();
	return classes;
};
