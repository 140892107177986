import { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { getStyles } from "./styles";
import { useMantineColorScheme } from "@mantine/core";
import { getCostantStyles } from "@/Costants/costantStyles";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { iFile, iFileManager } from "../../Types/FileManager";
import { v4 as uuidv4 } from "uuid";
import specialFileKeys from "@/Costants/specialFileKeys";
import EditorScreen from "@EditorScreen/index";
import { cookiesPaths } from "../../Costants/cookiesPaths";

// ! https://www.freecodecamp.org/news/how-to-build-react-based-code-editor/
const Landing = () => {
	const { t } = useTranslation();
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const classes = getStyles(colorScheme);
	const costantStyles = getCostantStyles(colorScheme);

	const cookieFile = Cookies.get(cookiesPaths.savedFile);

	const welcomeFile: iFile = {
		fileKey: specialFileKeys.welcomeFileKey,
		name: "Welcome",
		fileExtension: "txt",
		path: "/Welcome",
		langId: -1,
		code: "Welcome! Select a file to start from the left sidebar,\nor create a new one with the Create New File button above.",
		hideOnFileTree: true, //hide this file in the file tree
		disableCompilation: true,
	};
	const savedFile: iFile = cookieFile
		? {
				...JSON.parse(cookieFile),
				fileKey: uuidv4(),
		  }
		: {
				...welcomeFile,
				hideOnFileTree: false,
				langId: 0,
				fileExtension: "txt",

				fileKey: uuidv4(),
		  };

	const [fileManager, setFileManager] = useState<iFileManager>({
		[savedFile.fileKey]: {
			...savedFile,
		},
		[specialFileKeys.welcomeFileKey]: welcomeFile,
		// [uuidv4()]: {
		// 	name: "fileInFolder",
		// 	fileExtension: "c",
		// 	path: "/myFolder/fileInFolder",
		// 	code: "fiolder",
		// 	langId: 5,
		// },
	});

	return (
		<EditorScreen fileManager={fileManager} setFileManager={setFileManager} />
	);
};
export default Landing;
