import React, { useState, useEffect } from "react";
import {
	useMantineColorScheme,
	ColorScheme,
	Divider,
	Center,
	Button,
} from "@mantine/core";
import { createStyles, useMantineTheme } from "@mantine/styles";
import { getCostantStyles } from "@/Costants/costantStyles";
import { useTranslation } from "react-i18next";
import OutputWindow from "./OutputWindow";
import { VscDebugAlt } from "react-icons/vsc";
import { OutputDetailsType } from "./OutputWindow/OutputDetails";
import { NumberSize, Resizable } from "re-resizable";
import InputWindow from "./InputWindow";
import { statusCategoroies, statuses } from "../../../../Costants/statuses";
import {
	getWidthPercent,
	getHeightPercent,
} from "../../../../Functions/getDimensionsPercent";
import { Direction } from "re-resizable/lib/resizer";

interface iEditorTerminalProps {
	activeFileKey: string;
	outputDetails: OutputDetailsType;
	isProcessing: boolean;
	terminalHeight: number;
}

const EditorTerminal = ({
	activeFileKey,
	outputDetails,
	isProcessing,
	terminalHeight,
}: iEditorTerminalProps) => {
	const { t } = useTranslation();
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const classes = getStyles(colorScheme);
	const costantStyles = getCostantStyles(colorScheme);
	const theme = useMantineTheme();

	const [componentsDimensions, setComponentsDimensions] = useState({
		outputWindow: {
			width: getWidthPercent(65),
			height: "100%", //terminalHeight || getHeightPercent(25),
		},
	});

	const resizeOutputWindow = (
		event: MouseEvent | TouchEvent,
		direction: Direction,
		elementRef: HTMLElement,
		delta: NumberSize
	) => {
		setComponentsDimensions({
			...componentsDimensions,
			outputWindow: {
				width: componentsDimensions.outputWindow.width + delta.width,
				height: componentsDimensions.outputWindow.height, // + delta.height,
			},
		});
	};

	return (
		<div
			className={classes.terminalContainerDiv}
			style={{
				height: terminalHeight,
			}}
		>
			<Divider
				size="sm"
				orientation="horizontal"
				style={{
					width: "100%",
					paddingBottom: "0.2%",
				}}
			/>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
				}}
			>
				<Resizable
					defaultSize={componentsDimensions.outputWindow}
					size={componentsDimensions.outputWindow}
					onResizeStop={resizeOutputWindow}
					minWidth={getWidthPercent(25)}
					maxWidth={getWidthPercent(75)}
				>
					<OutputWindow
						outputDetails={outputDetails}
						outputWindowHeight={terminalHeight}
					/>
				</Resizable>

				<div
					style={{
						flex: 1,
					}}
				>
					<InputWindow
						disabled={
							Object.entries(outputDetails).length == 0 ||
							isProcessing ||
							outputDetails?.status?.id ===
								statuses["Completed successfully"].id ||
							statusCategoroies.errorStatuses.includes(
								outputDetails?.status?.id
							)
						}
					/>
				</div>
			</div>
		</div>
	);
};

export default EditorTerminal;

const getStyles = (colorScheme: ColorScheme) => {
	const getThemeStyles = createStyles((theme) => {
		const color =
			colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2];

		return {
			terminalContainerDiv: {
				display: "flex",
				flexDirection: "column",
				backgroundColor:
					colorScheme === "dark" ? theme.colors.gray[9] : theme.colors.gray[3],
				// backgroundColor: theme.colors.gray[9],
				// colorScheme === "dark" ? theme.colors.gray[9] : "#1e293b",
				height: "100%",
				paddingTop: "0.2%",
			},
		};
	});
	const { classes } = getThemeStyles();
	return classes;
};
