import { ColorScheme } from "@mantine/core";
import { createStyles } from "@mantine/styles";

export const getCostantStyles = (colorScheme: ColorScheme) => {
	const getThemeStyles = createStyles((theme) => {
		// const color =
		// 	colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2];

		return {
			screen: {
				display: "flex",
				flexDirection: "column",
				height: "100%",
				// width: "100%",
				// backgroundColor: "blue",
			},
		};
	});
	const { classes } = getThemeStyles();
	return classes;
};
