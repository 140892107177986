import React, { useState, useEffect, useContext } from "react";
import {
	useMantineColorScheme,
	ColorScheme,
	Textarea,
	Center,
	Button,
} from "@mantine/core";
import { createStyles } from "@mantine/styles";
import { getCostantStyles } from "@/Costants/costantStyles";
import { useTranslation } from "react-i18next";
import OutputDetails from "./OutputDetails";
import { FiSend } from "react-icons/fi";
import { FileManagerContext } from "../../../FileManagerContext";

interface iUserInput {
	disabled: boolean;
}

const UserInput = ({ disabled }: iUserInput) => {
	const { t } = useTranslation();
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const classes = getStyles(colorScheme);
	const costantStyles = getCostantStyles(colorScheme);

	const [customInput, setCustomInput] = useState("");
	//get fileManager context
	const { fileManagerTools } = useContext(FileManagerContext);

	return (
		<div className={classes.inputContainerDiv}>
			<Center
				style={{
					display: "flex",
					flexDirection: "column",
					width: "90%",
					flex: 1,
				}}
			>
				<Textarea
					placeholder={t("customUserInput.placeholder")}
					label={t("customUserInput.label")}
					onChange={(event) => setCustomInput(event.currentTarget.value)}
					style={{
						marginBottom: "1%",
						width: "90%",
					}}
					value={customInput}
					autosize
					minRows={2}
					disabled={disabled}
				/>
				<Center>
					<Button
						onClick={() => {
							if (!customInput) {
								alert(t("sendInputButton.alert"));
								return;
							}

							fileManagerTools.handleInputSend(customInput);
							setCustomInput("");
						}}
						size="lg"
						rightIcon={<FiSend />}
						disabled={disabled}
					>
						{t("sendInputButton.notLoading")}
					</Button>
				</Center>
			</Center>
		</div>
	);
};

export default UserInput;

const getStyles = (colorScheme: ColorScheme) => {
	const getThemeStyles = createStyles((theme) => {
		const color =
			colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2];

		return {
			inputContainerDiv: {
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				width: "100%",
				marginBottom: "1%",
			},
		};
	});
	const { classes } = getThemeStyles();
	return classes;
};
