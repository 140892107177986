import { iFileManager } from "@/Types/FileManager";

export const getAvailableFolders = (fileManager: iFileManager) => {
	const availableFolders: string[] = ["/"];
	Object.values(fileManager).forEach((file) => {
		const { path, name, code, fileExtension } = file;

		const pathParts = path.split("/");
		//remove fileName from path
		pathParts.pop();

		if (!availableFolders.includes(pathParts.join("/"))) {
			if (pathParts.join("/") == "" && !availableFolders.includes("/")) {
				availableFolders.push("/");
				return;
			}
			availableFolders.push(pathParts.join("/"));
		}
	});
	return availableFolders;
};
