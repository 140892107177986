import { createContext } from "react";
import { iFile, iFileManager } from "../../Types/FileManager";
import { iEditorSettings } from "../../Types/EditorSettings";

interface iEditorSettingsContext {
	editorSettings: iEditorSettings;
	editorSettingsTools: {
		handleThemeChange: (theme: string) => void;
	};
}

export const EditorSettingsContext = createContext<iEditorSettingsContext>(
	undefined as any
);
