import React, { useState, useEffect, useRef, useContext } from "react";
import { useMantineColorScheme, ColorScheme, Tabs } from "@mantine/core";
import { createStyles } from "@mantine/styles";
import EditorTabs from "../EditorTabs";
import EditorWrapper from "./EditorWrapper";
import { handleDownload } from "../../functions/handleDownload";
import { EditorTabsHandle } from "../EditorTabs/index";
import { iFile } from "@/Types/FileManager";
import { FileManagerContext } from "@/Components/EditorScreen/FileManagerContext";
import specialFileKeys from "@/Costants/specialFileKeys";
import EditorTerminal from "../EditorTerminal/index";
import { NumberSize, Resizable } from "re-resizable";
import { getHeightPercent } from "../../../../Functions/getDimensionsPercent";
import { Direction } from "re-resizable/lib/resizer";
import { OutputDetailsType } from "../EditorTerminal/OutputWindow/OutputDetails";
import ExecutionDialog from "./ExecutionDialog";

interface CodeEditorWindowProps {
	fileKey: string;
	onChange: (newCode: any) => void;
	onFileChange: (newFileKey: string | null) => void;
	theme: string;
	tabsRef: React.RefObject<EditorTabsHandle>;
	terminalProps: {
		activeFileKey: string;
		outputDetails: OutputDetailsType;
		isProcessing: boolean;
	};
}

const CodeEditorWindow = ({
	fileKey,
	onChange,
	onFileChange,
	theme,
	tabsRef,
	terminalProps,
}: CodeEditorWindowProps) => {
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const classes = getStyles(colorScheme);
	//get filemanger context
	const { fileManager, isProgramExecuting } = useContext(FileManagerContext);
	const fileInfo = fileManager[fileKey];
	const containerRef = useRef<HTMLDivElement>(null);

	const [componentsDimensions, setComponentsDimensions] = useState({
		terminal: {
			height: getHeightPercent(25),
			width: "100%",
		},
		editor: {
			height: getHeightPercent(60),
			width: "100%",
		},
	});

	const initTabs = [
		{
			label: fileInfo.name,
			fileExtension: fileInfo.fileExtension,
			langId: fileInfo.langId,
			path: fileInfo.path,
			fileKey: fileInfo.fileKey,
		},
	];

	const handleEditorChange = (newCode: string) => {
		onChange(newCode);
	};

	const resizeEditor = (
		event: MouseEvent | TouchEvent,
		direction: Direction,
		elementRef: HTMLElement,
		delta: NumberSize
	) => {
		const terminalHeight = containerRef.current
			? containerRef.current?.offsetHeight -
			  (componentsDimensions.editor.height + delta.height)
			: componentsDimensions.terminal.height - delta.height;

		setComponentsDimensions({
			...componentsDimensions,
			editor: {
				height: componentsDimensions.editor.height + delta.height,
				width: "100%",
			},
			terminal: {
				height: terminalHeight,
				width: "100%",
			},
		});
	};

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				height: "100%",
				width: "100%",
				paddingRight: "0.9%",
				marginLeft: "0.5%",
			}}
			ref={containerRef}
		>
			<EditorTabs
				ref={tabsRef}
				initTabs={initTabs}
				onTabChange={(tab) => {
					if (tab) {
						onFileChange(tab.fileKey);
						return;
					}
					onFileChange(specialFileKeys.welcomeFileKey);
				}}
				handleFileDownload={(activeFileKey) => {
					handleDownload(
						fileManager[activeFileKey].code,
						fileManager[activeFileKey].fileExtension,
						fileManager[activeFileKey].name
					);
				}}
				activeFileKey={fileKey}
			/>
			<Resizable
				defaultSize={componentsDimensions.editor}
				size={componentsDimensions.editor}
				onResizeStop={resizeEditor}
				maxHeight={
					containerRef.current
						? containerRef.current.offsetHeight * 0.95
						: getHeightPercent(90)
				}
			>
				<EditorWrapper
					onChange={handleEditorChange}
					fileInfo={fileInfo}
					theme={theme}
					editorHeight={componentsDimensions.editor.height}
				/>
			</Resizable>

			<ExecutionDialog
				isOpen={isProgramExecuting}
				position={{
					top: "20%",
					right: 10,
				}}
			/>
			<EditorTerminal
				{...terminalProps}
				terminalHeight={componentsDimensions.terminal.height}
			/>
		</div>
	);
};
export default CodeEditorWindow;

const getStyles = (colorScheme: ColorScheme) => {
	const getThemeStyles = createStyles((theme) => {
		return {};
	});
	const { classes } = getThemeStyles();
	return classes;
};
