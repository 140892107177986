import {
	uniqueNamesGenerator,
	Config,
	adjectives,
	colors,
	animals,
} from "unique-names-generator";

export const generateRandomName = (end?: string): string => {
	const config: Config = {
		dictionaries: [adjectives, animals],
		separator: "-",
	};

	return `${uniqueNamesGenerator(config)}${end || ""}`;
};
