import { createContext } from "react";
import { iFile, iFileManager } from "../../Types/FileManager";

interface iFileManagerContext {
	fileManager: iFileManager;
	isProgramExecuting: boolean;
	fileManagerTools: {
		addFile: (file: iFile) => void;
		editFile: (
			fileKey: string,
			newName?: string,
			newPath?: string,
			newLangId?: number
		) => void;
		deleteFile: (fileKey: string) => void;
		compileFile: (fileKey?: string) => void;
		handleInputSend: (input: string) => void;
		killProcess: () => void;
	};
}

export const FileManagerContext = createContext<iFileManagerContext>(
	undefined as any
);
