import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

//lngs defined in App.tsx
i18n
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		debug: false,
		fallbackLng: "en",
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		resources: {
			en: {
				translation: {
					dropdown: {
						language: "Select language",
						theme: "Select Editor theme",
					},
					compileButton: {
						loading: "Processing...",
						notLoading: "Compile and Execute",
					},
					sendInputButton: {
						alert: "Input empty, write something before sending!",
						notLoading: "Send input",
					},
					downloadFileButton: "Download file",

					customUserInput: {
						label: "User input",
						placeholder: "insert input here...",
					},

					runResult: {
						success: "Completed successfully!",
						error: "Error",
					},
				},
			},
			it: {
				translation: {
					dropdown: {
						language: "Seleziona linguaggio",
						theme: "Seleziona tema dell'editor",
					},
					compileButton: {
						loading: "Caricamento...",
						notLoading: "Compila e esegui",
					},
					sendInputButton: {
						alert: "Input vuoto, inserisci qualcosa prima di inviare!",
						notLoading: "Invia input",
					},
					downloadFileButton: "Scarica il file",
					customUserInput: {
						label: "Input utente",
						placeholder: "inserisci qui...",
					},
					runResult: {
						success: "Completato con successo!",
						error: "Errore",
					},
				},
			},
		},
	});

export default i18n;
