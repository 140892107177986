export interface Status {
	id: number;
	description: string;
}

const errorStatuses = [6, 404, 405];
const stdoutStatuses = [2, 3];
const stderrStatuses = [4, 5];
export const statusCategoroies = {
	errorStatuses: errorStatuses,
	stdoutStatuses: stdoutStatuses,
	stderrStatuses: stderrStatuses,
};

export const statuses: {
	[key: string]: Status;
} = {
	Compiling: { id: 0, description: "Compiling" },
	Running: { id: 1, description: "Running" },
	Waiting: { id: 2, description: "Waiting for input" },
	"Completed successfully": { id: 3, description: "Completed successfully" },
	"Compile Error": { id: 4, description: "Compile Error" },
	"Runtime Error": { id: 5, description: "Runtime Error" },
	"Timeout Exceeded": { id: 6, description: "Timeout Exceeded" },
	"Process Killed Successfully": {
		id: 7,
		description: "Process Killed Successfully",
	},

	"Process Not Found": { id: 404, description: "Process Not Found" },
	"Server Internal Error": { id: 405, description: "Server Internal Error" },
};
