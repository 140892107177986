export interface iLanguage {
	id: number;
	name: string;
	label: string;
	value: string;
	fileExtension: string;
	defaultCode: string;
}

export interface iJavaLanguage extends iLanguage {
	getDefaultCode: (fileName: string) => string;
}

export interface iLanguageOptions {
	"2": iJavaLanguage;
	[id: string]: iLanguage | iJavaLanguage;
}

const cppDefaultCode = `#include <iostream>

int main() {
    std::cout << "Hello World!";
    return 0;
}`;
const cDefaultCode = `#include <stdio.h>

int main() {
	printf("Hello World");
	return 0;
}`;
const javaDefaultCode = `public class Hello {

   public static void main(String[] args) {

      System.out.println("Hello, World!");

   }
}`;

const pyDefaultCode = `print("Hello World!")`;
const jsDefaultCode = `console.log("Hello World!");`;

const cFileExtension = "c";
const cppFileExtension = "cpp";
const pyFileExtension = "py";
const jsFileExtension = "js";
const javaFileExtension = "java";

let languageOptions: iLanguageOptions = {
	0: {
		id: 0,
		name: "C",
		label: "C",
		value: "c",
		fileExtension: cFileExtension,
		defaultCode: cDefaultCode,
	},
	1: {
		id: 1,
		name: "C++",
		label: "C++",
		value: "cpp",
		fileExtension: cppFileExtension,
		defaultCode: cppDefaultCode,
	},
	2: {
		id: 2,
		name: "Java",
		label: "Java",
		value: "java",
		fileExtension: javaFileExtension,
		defaultCode: javaDefaultCode,
		getDefaultCode: (fileName: string) => {
			return `public class ${fileName} {

   public static void main(String[] args) {

      System.out.println("Hello, World!");

   }
}`;
		},
	},
	3: {
		id: 3,
		name: "JavaScript",
		label: "JavaScript",
		value: "javascript",
		fileExtension: jsFileExtension,
		defaultCode: jsDefaultCode,
	},
	4: {
		id: 4,
		name: "Python",
		label: "Python",
		value: "python",
		fileExtension: pyFileExtension,
		defaultCode: pyDefaultCode,
	},
};

export const setLanguageOptions = (data: iLanguageOptions) => {
	// languageOptions = data;
};

export { languageOptions };
