import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { getAppStyles } from "@/App.styles";

//import icons
import { MdHome, MdOutlineCreditScore } from "react-icons/md";

// talk to rust with
// import { invoke } from '@tauri-apps/api/tauri'

// imported views need to be added to `views`
import Home from "@/Screens/Home";
import About from "@/Screens/About";
import CustomAppShell, { iViews } from "@/Components/CustomAppShell";
import { useMantineColorScheme } from "@mantine/core";

import { GB, IT, FlagComponent } from "country-flag-icons/react/3x2";
import {
	getWidthPercent,
	getHeightPercent,
} from "@/Functions/getDimensionsPercent";

const langImageStyle = {
	backgroundColor: "red",
	width: getWidthPercent(1.5),
};

export interface SiteLanguage {
	nativeName: string;
	image: any;
	key: string;
}
export const lngs: {
	[key: string]: SiteLanguage;
} = {
	en: {
		nativeName: "English",
		key: "en",
		image: <GB style={langImageStyle} />,
	},
	it: {
		nativeName: "Italiano",
		key: "it",
		image: <IT style={langImageStyle} />,
	},
};

const App = () => {
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const classes = getAppStyles(colorScheme);
	// left sidebar
	const views: iViews = [
		{ component: Home, path: "/", name: "Home", icon: MdHome },
		{
			component: About,
			path: "/about",
			name: "About",
			icon: MdOutlineCreditScore,
		},
	];

	return (
		<CustomAppShell views={views}>
			<Routes>
				{views.map((view, elIndex) => {
					if (view.path == "/") {
						return (
							<Route
								key={elIndex}
								path={view.path}
								element={<view.component />}
							/>
						);
					}
					return (
						<Route
							key={elIndex}
							path={view.path}
							element={<view.component />}
						/>
					);
				})}
			</Routes>
		</CustomAppShell>
	);
};

export default App;
