import React from "react";
import { getStyles } from "./styles";
import {
	Center,
	Loader,
	Skeleton,
	useMantineColorScheme,
	Title,
} from "@mantine/core";
import { getCostantStyles } from "@/Costants/costantStyles";

const LoadingScreen = () => {
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const classes = getStyles(colorScheme);
	const costantStyles = getCostantStyles(colorScheme);

	return (
		<div
			className={costantStyles.screen}
			style={{
				justifyContent: "center",
			}}
		>
			<Center
				style={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Title
					style={{
						marginBottom: "3%",
					}}
				>
					Loading...
				</Title>
				<Loader size="xl" />
			</Center>
		</div>
	);
};

export default LoadingScreen;
