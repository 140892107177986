import React from "react";
import { forwardRef } from "react";
import { Group, Avatar, Text, Select } from "@mantine/core";
import { US } from "country-flag-icons/react/3x2";
import { getHeightPercent } from "@/Functions/getDimensionsPercent";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
	image: any;
	label: string;
	description: string;
}

const LngsSelectItem = forwardRef<HTMLDivElement, ItemProps>(
	({ image, label, ...others }: ItemProps, ref) => {
		return (
			<div
				ref={ref}
				{...others}
				style={{
					width: "100%",
				}}
			>
				<Group noWrap>
					{image}
					<div>
						<Text size="sm">{label}</Text>
					</div>
				</Group>
			</div>
		);
	}
);

export default LngsSelectItem;
