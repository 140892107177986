import { IoLogoJavascript } from "react-icons/io5";
import { DiJava, DiJavascript, DiPython } from "react-icons/di";
import { SiCplusplus, SiJavascript } from "react-icons/si";
import { IconType } from "react-icons";
import { HiOutlineHashtag } from "react-icons/hi";

interface LanguagesIcons {
	js: IconType;
	java: IconType;
	py: IconType;
	cpp: IconType;
	// c: IconType;
	default: IconType;
	[key: string]: IconType;
}

export const languagesIcons: LanguagesIcons = {
	js: SiJavascript,
	java: DiJava,
	py: DiPython,
	cpp: SiCplusplus,
	default: HiOutlineHashtag,
};
