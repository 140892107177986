import React from "react";
import {
	useMantineColorScheme,
	Button,
	Affix,
	Transition,
} from "@mantine/core";
import { createStyles } from "@mantine/styles";
import { getCostantStyles } from "@/Costants/costantStyles";
import { useTranslation } from "react-i18next";
import { useWindowScroll } from "@mantine/hooks";
import { IoArrowUpCircleOutline } from "react-icons/io5";
import { ColorScheme } from "@mantine/core";

const Footer = () => {
	const { t } = useTranslation();
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const classes = getStyles(colorScheme);
	const costantStyles = getCostantStyles(colorScheme);
	const [scroll, scrollTo] = useWindowScroll();

	const onScrollClick = () => {
		scrollTo({ y: 0 } as any);
	};

	return (
		<Affix position={{ bottom: 20, right: 20 }}>
			<Transition transition="slide-up" mounted={scroll.y > 0}>
				{(transitionStyles) => (
					<div>
						<Button
							leftIcon={<IoArrowUpCircleOutline size={25} />}
							style={transitionStyles}
							onClick={onScrollClick}
						>
							Scroll to top
						</Button>
					</div>
				)}
			</Transition>
		</Affix>
	);
};

export default Footer;

const getStyles = (colorScheme: ColorScheme) => {
	const getThemeStyles = createStyles((theme) => {
		const color =
			colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2];

		return {};
	});
	const { classes } = getThemeStyles();
	return classes;
};
