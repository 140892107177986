export const wsStatuses = {
	0: {
		name: "Closed",
		id: 0,
	},
	1: {
		name: "Sending editor data",
		id: 1,
	},
	2: {
		name: "Sending input data",
		id: 2,
	},
	3: {
		name: "Sending output data",
		id: 3,
	},
	444: {
		name: "Stop execution",
		id: 444,
	},
};
