import { toast } from "react-toastify";

export const showSuccessToast = (msg?: string) => {
	toast.success(msg || `Compiled Successfully!`, {
		position: "top-right",
		autoClose: 1500,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});
};
export const showWarningToast = (msg?: string) => {
	toast.warn(msg || `Warning!`, {
		position: "top-right",
		autoClose: 1500,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});
};
export const showInfoToast = (msg?: string) => {
	toast.info(msg || `Some info`, {
		position: "top-right",
		autoClose: 1500,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});
};
export const showErrorToast = (msg?: string) => {
	toast.error(msg || `Something went wrong! Please try again.`, {
		position: "top-right",
		autoClose: 2500,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});
};
