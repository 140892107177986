import { useMantineColorScheme, ColorScheme } from "@mantine/core";
import { createStyles } from "@mantine/styles";
import { getCostantStyles } from "@/Costants/costantStyles";
import { useTranslation } from "react-i18next";
import { VscDebugAlt, VscDebugStop } from "react-icons/vsc";
import TabsIconFactory, {
	TabsIconFactoryChild,
} from "../../EditorTabs/EditorTabsIcons/TabsIconFactory";

const KillProcessIcon = ({ forwardRef, onClick }: TabsIconFactoryChild) => {
	const { t } = useTranslation();
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const classes = getStyles(colorScheme);
	const costantStyles = getCostantStyles(colorScheme);

	return (
		<TabsIconFactory
			tooltip="Stop execution"
			Icon={VscDebugStop}
			forwardRef={forwardRef}
			onClick={onClick}
			size={40}
		/>
	);
};

export default KillProcessIcon;

const getStyles = (colorScheme: ColorScheme) => {
	const getThemeStyles = createStyles((theme) => {
		const color =
			colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2];

		return {};
	});
	const { classes } = getThemeStyles();
	return classes;
};
