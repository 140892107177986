import React, {
	forwardRef,
	useImperativeHandle,
	useState,
	useContext,
} from "react";
import {
	useMantineColorScheme,
	ColorScheme,
	Modal,
	TextInput,
	Select,
} from "@mantine/core";
import { createStyles } from "@mantine/styles";
import { getCostantStyles } from "@/Costants/costantStyles";
import { useTranslation } from "react-i18next";
import { FileManagerContext } from "@/Components/EditorScreen/FileManagerContext";
import { getAvailableFolders } from "@/tools/FileManagerTools/getAvailableFolders";
import { Button } from "@mantine/core";
import { getForbiddenNames } from "@/tools/FileManagerTools/getForbiddenNames";
import { languageOptions, iLanguage } from "@/Costants/languageOptions";
import { iFile } from "@/Types/FileManager";

interface iEditFilenameModal {
	fileKey: string;
	onFileEditEnter: (
		fileKey: string,
		newName?: string,
		newPath?: string,
		newLangId?: number
	) => void;
}
export interface EditFilenameModalHandle {
	open: () => void;
	close: () => void;
	getModalState: () => boolean;
	refreshFileStats: (
		newName: string,
		newPath: string,
		newLangId: number
	) => void;
}

const EditFilenameModal = forwardRef<
	EditFilenameModalHandle,
	iEditFilenameModal
>(({ fileKey, onFileEditEnter }, ref) => {
	const { t } = useTranslation();
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const classes = getStyles(colorScheme);
	const costantStyles = getCostantStyles(colorScheme);
	const [modalOpened, setModalOpened] = useState(false);

	//get filemanager
	const { fileManager, fileManagerTools } = useContext(FileManagerContext);
	const file = fileManager[fileKey];
	const [availableFileLoc, setAvailableFileLoc] = useState(
		getAvailableFolders(fileManager)
	);
	const [forbiddenNames, setForbiddenNames] = useState(
		getForbiddenNames(fileManager).filter((name) => name != `${file?.name}`)
	);

	const [fileName, setFileName] = useState(file?.name);
	const [fileLoc, setfileLoc] = useState(
		file?.path.split("/").slice(0, -1).join("/")
	);
	const [fileExtension, setFileExtension] = useState<iLanguage>(
		languageOptions[file?.langId]
	);

	useImperativeHandle(ref, () => ({
		open: () => setModalOpened(true),
		close: () => setModalOpened(false),
		getModalState: () => modalOpened,
		refreshFileStats: (newName, newPath, newLangId) => {
			setFileName(newName);
			setfileLoc(newPath.split("/").slice(0, -1).join("/"));
			setFileExtension(languageOptions[newLangId]);
			setForbiddenNames(
				getForbiddenNames(fileManager).filter((name) => name != `${newName}`)
			);
		},
	}));

	const onConfirmPress = () => {
		if (forbiddenNames.includes(`${fileName}`)) {
			alert(
				`A file with the name "${fileName}" already exists: change file name`
			);
			return;
		}
		if (!fileName) {
			alert("Please enter a file name");
			return;
		}

		const newName = fileName !== file?.name ? fileName : undefined;

		let filePath: string | undefined = "";
		if (file.path !== `${fileLoc}/${fileName}`) {
			if (fileLoc === "/") {
				filePath = `${fileLoc}${fileName}`;
			} else filePath = `${fileLoc}/${fileName}`;
		} else filePath = undefined;
		const langId =
			fileExtension.id !== file.langId ? fileExtension.id : undefined;

		setModalOpened(false);
		onFileEditEnter(fileKey, newName, filePath, langId);
	};

	return (
		<Modal
			opened={modalOpened}
			onClose={() => setModalOpened(false)}
			title="Edit file"
		>
			<div>
				<TextInput
					placeholder="filename..."
					label="File name:"
					value={fileName}
					onChange={(event) => setFileName(event.currentTarget.value)}
					required
				/>
				<Select
					label="File type:"
					placeholder="File type..."
					data={Object.values(languageOptions).map((l) => {
						return {
							label: l.label,
							value: l.id.toString(),
						};
					})}
					onChange={(v) => {
						setFileExtension(languageOptions[v as string]);
					}}
					value={fileExtension.id.toString()}
					required
					searchable
				/>
				<Select
					label="File location:"
					placeholder="File location..."
					data={availableFileLoc.map((loc) => {
						return {
							label: loc,
							value: loc,
						};
					})}
					onChange={(loc) => {
						setfileLoc(loc as string);
					}}
					value={fileLoc}
					required
					searchable
				/>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						paddingTop: "2%",
					}}
				>
					<Button size="lg" onClick={onConfirmPress}>
						Save changes
					</Button>
				</div>
			</div>
		</Modal>
	);
});

export default EditFilenameModal;

const getStyles = (colorScheme: ColorScheme) => {
	const getThemeStyles = createStyles((theme) => {
		const color =
			colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2];

		return {};
	});
	const { classes } = getThemeStyles();
	return classes;
};
