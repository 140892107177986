import {
	useMantineColorScheme,
	useMantineTheme,
	ColorScheme,
} from "@mantine/core";
import { createStyles } from "@mantine/styles";
import { getCostantStyles } from "@/Costants/costantStyles";
import UserInput from "../OutputWindow/UserInput";
import { statusCategoroies } from "../../../../../Costants/statuses";

interface iInputWindow {
	disabled: boolean;
}

const InputWindow = ({ disabled }: iInputWindow) => {
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const theme = useMantineTheme();
	const classes = getStyles(colorScheme);
	const costantStyles = getCostantStyles(colorScheme);

	return (
		<div className={classes.containerDiv}>
			<UserInput disabled={disabled} />
		</div>
	);
};

export default InputWindow;

const getStyles = (colorScheme: ColorScheme) => {
	const getThemeStyles = createStyles((theme) => {
		return {
			containerDiv: {
				display: "flex",
				flexDirection: "column",
				width: "100%",
				height: "100%",
				paddingLeft: "2%",
				paddingTop: "2%",
				backgroundColor:
					colorScheme === "dark" ? theme.colors.dark[7] : "white",
			},
		};
	});
	const { classes } = getThemeStyles();
	return classes;
};
