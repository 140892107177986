import { useState, useEffect, useRef } from "react";
import { useMantineColorScheme, ColorScheme } from "@mantine/core";
import { createStyles } from "@mantine/styles";
import Editor, { loader, OnChange } from "@monaco-editor/react";
import { monacoThemes } from "@/lib/defineTheme";
import { languageOptions } from "@/Costants/languageOptions";
import { generateRandomName } from "@/Functions/generateRandomName";
import { iFile } from "@/Types/FileManager";

interface CodeEditorWindowProps {
	onChange: (newCode: any) => void;
	fileInfo: iFile;
	theme: string;
	editorHeight?: string | number;
	style?: any;
}

const EditorWrapper = ({
	onChange,
	fileInfo,
	theme,
	editorHeight,
	style,
}: CodeEditorWindowProps) => {
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const classes = getStyles(colorScheme);
	const initLang = languageOptions[fileInfo.langId] || languageOptions[0];

	const [language, setLanguage] = useState(initLang);
	const [fileName, setFileName] = useState(
		generateRandomName(initLang.fileExtension)
	);

	const [code, setCode] = useState(fileInfo.code || "");
	const containerRef = useRef<HTMLDivElement>(null);
	const [containerHeight, setContainerHeight] = useState(0);

	const [editorPaddingColor, setEditorPaddingColor] = useState("");

	useEffect(() => {
		const changePaddingColor = async () => {
			if (["light", "vs-dark"].includes(theme)) {
				switch (theme) {
					case "light":
						setEditorPaddingColor("#f5f5f5");
						break;
					case "vs-dark":
						setEditorPaddingColor("#1e1e1e");
						break;
					default:
						break;
				}
				return;
			}

			Promise.all([
				loader.init(),
				import(`monaco-themes/themes/${monacoThemes[theme]}.json`),
			])
				.then(([monaco, themeData]) => {
					setEditorPaddingColor(
						themeData.colors["editor.background"] || "transparent"
					);
				})
				.catch((err) => {});
		};

		changePaddingColor();
	}, [theme]);

	useEffect(() => {
		const lang = languageOptions[fileInfo.langId] || languageOptions[0];

		setLanguage(lang);
		setFileName(generateRandomName(`.${lang.fileExtension}`));
		setCode(fileInfo.code || "");
	}, [fileInfo.langId]);

	useEffect(() => {
		if (containerRef.current) {
			setContainerHeight(containerRef.current.offsetHeight);
		}
	}, [editorHeight]);

	const handleEditorChange = (newValue: string) => {
		setCode(newValue);
		onChange(newValue);
	};

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				height: "100%",

				width: "100%",
				...style,
			}}
			ref={containerRef}
		>
			<div
				style={{
					backgroundColor: editorPaddingColor || "transparent",
					padding: "2%",
					// borderRadius: "0px 0px 15px 15px",
					// flex: 1,
					height: containerHeight,
				}}
			>
				<Editor
					height={containerHeight * 0.9 || "100%"}
					width={`100%`}
					language={language.value || "txt"}
					value={code}
					theme={theme}
					defaultValue="// some comment"
					onChange={handleEditorChange as OnChange}
					className={classes.editor}
					wrapperProps={{
						className: classes.wrapper,
					}}
				/>
			</div>
		</div>
	);
};
export default EditorWrapper;

const getStyles = (colorScheme: ColorScheme) => {
	const getThemeStyles = createStyles((theme) => {
		return {
			editor: {},
			wrapper: {},
		};
	});
	const { classes } = getThemeStyles();
	return classes;
};
