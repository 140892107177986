import React, { useState, useEffect, useContext } from "react";
import {
	useMantineColorScheme,
	ColorScheme,
	TextInput,
	Select,
	Button,
} from "@mantine/core";
import { createStyles } from "@mantine/styles";
import { getCostantStyles } from "@/Costants/costantStyles";
import { useTranslation } from "react-i18next";
import { generateRandomName } from "@/Functions/generateRandomName";
import { languageOptions, iLanguage } from "@/Costants/languageOptions";
import { getAvailableFolders } from "@/tools/FileManagerTools/getAvailableFolders";
import { FileManagerContext } from "@/Components/EditorScreen/FileManagerContext";
import { capitalize, capitalizeFirstLetter } from "@/Functions/capitalize";

export interface iNewFileForm {
	forbiddenNames: string[];
	onCreatePress: (fileName: string, langId: number, folder: string) => void;
	defaultPath?: string;
}

const NewFileForm = ({
	forbiddenNames,
	onCreatePress,
	defaultPath,
}: iNewFileForm) => {
	const { t } = useTranslation();
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const classes = getStyles(colorScheme);
	const costantStyles = getCostantStyles(colorScheme);
	const { fileManager } = useContext(FileManagerContext);

	const [fileName, setFileName] = useState(generateRandomName());
	const [fileExtension, setFileExtension] = useState(languageOptions[0]);
	const availableFileLoc = getAvailableFolders(fileManager);

	const path = defaultPath
		? fileManager[defaultPath].path
				.split("/") //remove last element: the file name
				.splice(0, fileManager[defaultPath].path.split("/").length - 1)
				.join("/")
		: availableFileLoc[0];
	const [fileLocation, setFileLocation] = useState(
		path.length == 0 ? "/" : path
	);

	const onConfirmPress = () => {
		if (forbiddenNames.includes(`${fileName}`)) {
			alert(
				`A file with the name "${fileName}" already exists: change file name`
			);
			return;
		}

		if (!fileName) {
			alert("Please enter a file name");
			return;
		}

		//se è java controlla che la prima lettera sia uppercase
		if (fileExtension.id == 2) {
			const char = fileName[0];
			if (char != char.toUpperCase()) {
				const correctName = capitalizeFirstLetter(fileName);
				alert(
					`The first character of the name of the Java file needs to be uppercase: change "${fileName}" to "${correctName}"`
				);
				return;
			}
		}

		onCreatePress(fileName, fileExtension.id, fileLocation);
	};

	return (
		<div>
			<TextInput
				placeholder="filename..."
				label="New file name:"
				value={fileName}
				onChange={(event) => setFileName(event.currentTarget.value)}
				required
			/>
			<Select
				label="File type:"
				placeholder="File type..."
				data={Object.values(languageOptions).map((l) => {
					return {
						label: l.label,
						value: l.id.toString(),
					};
				})}
				onChange={(v) => {
					//se è java allora fai iniziare il nome con una lettera maiuscola
					if (v == "2") {
						setFileName(
							capitalize(fileName.split("-").join(" ")).split(" ").join("")
						);
					}
					setFileExtension(languageOptions[v as string]);
				}}
				value={fileExtension.id.toString()}
				required
				searchable
			/>
			<Select
				label="File location:"
				placeholder="File location..."
				data={availableFileLoc.map((loc) => {
					return {
						label: loc,
						value: loc,
					};
				})}
				onChange={(loc) => {
					setFileLocation(loc as string);
				}}
				value={fileLocation}
				required
				searchable
			/>

			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					paddingTop: "2%",
				}}
			>
				<Button size="lg" onClick={onConfirmPress}>
					Create file
				</Button>
			</div>
		</div>
	);
};

export default NewFileForm;

const getStyles = (colorScheme: ColorScheme) => {
	const getThemeStyles = createStyles((theme) => {
		const color =
			colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2];

		return {};
	});
	const { classes } = getThemeStyles();
	return classes;
};
