import { MantineTheme, ColorScheme } from "@mantine/core";
import { createStyles } from "@mantine/styles";

export const getStyles = (colorScheme: ColorScheme) => {
	const getThemeStyles = createStyles((theme) => {
		const color =
			colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2];

		return {
			dropdownContainerDiv: {
				display: "flex",
				flexDirection: "row",
				justifyContent: "flex-end",
				marginBottom: "0.5%",
			},
			dropdownDiv: {
				width: "40%",
			},

			editorContainerDiv: {
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-around",
				width: "100%",
				height: "100%",
			},
			fileTreeDiv: {
				minWidth: "13%",
				marginRight: "0px",
			},
			editorDiv: {
				// width: "60%",
				marginLeft: "0px",
				flex: 1,
				height: "90%",
			},
			outputContainerDiv: {
				display: "flex",
				flexDirection: "row",

				// width: "22%",
				paddingLeft: "0.2%",
				height: "100%",
			},
			downloadFileDiv: {
				display: "flex",
				flexDirection: "column",
				justifyContent: "flex-end",
			},

			inputContainerDiv: {
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
			},
		};
	});
	const { classes } = getThemeStyles();
	return classes;
};
