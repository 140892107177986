import { iFileManager } from "@/Types/FileManager";
import { iFolderTreeData } from "@EditorScreen/Components/Sidebar/SidebarComponents/FileTree";

export const getFileTree = (fileManager: iFileManager) => {
	const newTreeData: iFolderTreeData = {
		name: "root",
		children: [],
	};

	Object.entries(fileManager).forEach(([key, file]) => {
		const { path, name, code, fileExtension, hideOnFileTree } = file;

		const pathParts = path.split("/");
		//remove fileName from path
		pathParts.pop();

		if (pathParts.length == 0 && !hideOnFileTree) {
			(newTreeData["children"] as iFolderTreeData[]).push({
				name: `${name}.${fileExtension}`,
				fileManagerPath: path,
				code: file.code,
				fileKey: file.fileKey,
			});
		} else {
			let currentNode = newTreeData;
			for (const part of pathParts) {
				if (part === "") continue;
				const foundNode = currentNode?.children?.find(
					(node) => node.name === part
				);
				if (foundNode) {
					currentNode = foundNode;
				} else {
					const newNode: iFolderTreeData = {
						name: part,
						children: [],
					};
					(currentNode["children"] as iFolderTreeData[]).push(newNode);
					currentNode = newNode;
				}
			}
			if (currentNode && !hideOnFileTree) {
				currentNode?.children?.push({
					name: `${name}.${fileExtension}`,
					code: file.code,
					fileKey: file.fileKey,
					fileManagerPath: path,
				});
			}
		}
	});

	return newTreeData;
};
