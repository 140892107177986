import React, { useState, useEffect } from "react";
import {
	AppShell,
	Navbar,
	Header,
	Text,
	MediaQuery,
	Burger,
	ActionIcon,
	Group,
} from "@mantine/core";
import { useMantineColorScheme, ColorScheme, Select } from "@mantine/core";
import { IoSunnySharp } from "react-icons/io5";
import { BsMoonStarsFill } from "react-icons/bs";
import { createStyles, useMantineTheme } from "@mantine/styles";
import { NavLink } from "react-router-dom";
import LngsSelectItem from "./LngsSelectItem";
import { lngs } from "../App";
import i18n from "../i18n";
import Cookies from "js-cookie";
import LoadingScreen from "../Screens/Loading/index";
import { requestToApi } from "../Functions/requestToApi";
import apiEndpoints from "../Costants/apiEndpoints";
import { setLanguageOptions } from "../Costants/languageOptions";
import Footer from "./Footer";
import {
	getWidthPercent,
	getHeightPercent,
} from "../Functions/getDimensionsPercent";

interface iProps {
	children: React.ReactNode;
	views: iViews;
}
export type iViews = Array<{
	component: React.ComponentType<any>;
	icon: React.ComponentType<any>;
	path: string;
	name: string;
}>;

const HEADER_HEIGHT = 70;

const App = ({ children, views }: iProps) => {
	const [loading, setLoading] = useState(true);

	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const [mobileNavOpened, setMobileNavOpened] = useState(false);
	const [screenDimensions, setScreenDimensions] = useState({
		width: getWidthPercent(100),
		height: getHeightPercent(100),
	});
	const savedLanguage =
		lngs[Cookies.get("siteLanguage") as string] ||
		lngs[i18n.language] ||
		lngs.en;
	const [lng, setLng] = useState(savedLanguage);

	useEffect(() => {
		const init = async () => {
			i18n.changeLanguage(savedLanguage.key);
			const { errorHappened, errorInfo, data, ...res } = await requestToApi(
				apiEndpoints.languagesData
			);
			if (!errorHappened) {
				setLanguageOptions(data);
			}

			setLoading(false);
		};
		init();

		const handleScreenChange = () => {
			setScreenDimensions({
				width: getWidthPercent(100),
				height: getHeightPercent(100),
			});
		};
		window.addEventListener("resize", handleScreenChange);
		return () => {
			window.removeEventListener("resize", handleScreenChange);
		};
	}, []);

	const classes = getStyles(colorScheme);

	const handleLngChange = (newLng: string) => {
		if (newLng == lng.key) return;

		setLng(lngs[newLng]);
		i18n.changeLanguage(newLng);

		Cookies.set("siteLanguage", newLng, {
			expires: 365000,
			sameSite: "lax",
			path: "/",
		});
	};

	function NavLinks() {
		return (
			<div>
				{views.map((view, index) => (
					<NavLink
						to={view.path}
						key={index}
						onClick={(_) => setMobileNavOpened(false)}
						className={({ isActive }) =>
							classes.navLink +
							" " +
							(isActive ? classes.navLinkActive : classes.navLinkInactive)
						}
					>
						<view.icon
							style={{
								marginRight: "3%",
							}}
							size={"1.5rem"}
						/>
						<Group>
							<Text>{view.name}</Text>
						</Group>
					</NavLink>
				))}
			</div>
		);
	}

	return (
		<AppShell
			// padding="md"
			fixed
			className={classes.appShell}
			navbar={
				mobileNavOpened ? (
					<Navbar
						height="100%"
						width={{
							xs: mobileNavOpened ? "200px" : "0px",
							sm: mobileNavOpened ? "200px" : "0px",
							md: mobileNavOpened ? "200px" : "0px",
							lg: mobileNavOpened ? "200px" : "0px",
							xl: mobileNavOpened ? "200px" : "0px",
						}}
						p="xs"
						className={classes.navbar}
					>
						<NavLinks />
					</Navbar>
				) : (
					<></>
				)
			}
			header={
				<Header height={HEADER_HEIGHT} p="md" className={classes.header}>
					<Burger
						opened={mobileNavOpened}
						onClick={() => {
							setMobileNavOpened((o) => !o);
						}}
						size="md"
						mr="xl"
						color={useMantineTheme().colors.gray[6]}
					/>
					<Text>Online compiler</Text>
					<Group className={classes.headerRightItems}>
						<Select
							placeholder={`Select language...`}
							data={Object.entries(lngs).map(([key, langVal]) => {
								return {
									label: langVal.nativeName,
									value: key,
									image: langVal.image,
									key,
								};
							})}
							itemComponent={LngsSelectItem}
							onChange={handleLngChange}
							value={lng.key}
							defaultValue={lng.key}
							icon={lng.image || null}
							searchable
						/>
						<ActionIcon
							title="Ctrl + J"
							// className={classes.actionIcon}
							variant="default"
							onClick={() => {
								toggleColorScheme();
							}}
							size={30}
						>
							{/* icon to show based on colorScheme */}
							{colorScheme === "dark" ? (
								<IoSunnySharp size={"1.5em"} />
							) : (
								<BsMoonStarsFill />
							)}
						</ActionIcon>
					</Group>
				</Header>
			}
			// footer={
			// 	<Footer height={"fit-content"} p="xs" className={classes.footer}>
			// 		FOOTER TEXT
			// 		Click button to close footer: to be implemented
			// 		<Button
			// 			variant="subtle"
			// 			size="xs"
			// 			onClick={() => {
			// 				console.log("click");
			// 			}}
			// 		>
			// 			<ImCross />
			// 		</Button>
			// 	</Footer>
			// }
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					// backgroundColor: "red",
					// width: screenDimensions.width - 10,
					height: screenDimensions.height - HEADER_HEIGHT - 10,
				}}
			>
				{loading ? <LoadingScreen /> : children}
				<Footer />
			</div>
			{/* <div
				style={{
					backgroundColor: "red",
					display: "flex",
					flexDirection: "column",
					height: "100%",
				}}
			>
				sa
			</div> */}
		</AppShell>
	);
};

export default App;

const getStyles = (colorScheme: ColorScheme) => {
	const navbarOpacity = 0.95;
	const getThemeStyles = createStyles((theme) => {
		return {
			appShell: {
				main: {
					marginTop: `${HEADER_HEIGHT}px`,
					padding: "0px",
					marginRight: "0px",
					marginLeft: "0px",
					width: "100%",

					// backgroundColor: "red",
				},
				body: {
					// backgroundColor: "blue",
				},
			},
			navLink: {
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				width: "100%",
				padding: theme.spacing.xs,
				borderRadius: theme.radius.md,
				color: colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
				textDecoration: "none",
				willChange: "transform",
				marginTop: "2%",

				"&:hover:active": {
					transform: "translateY(2px)",
				},
			},
			navbar: {
				backgroundColor:
					colorScheme === "dark"
						? `rgba(26, 27, 30, ${navbarOpacity})` //theme.colors.dark[7]
						: `rgba(255, 255, 255, ${navbarOpacity})`,
			},
			navLinkActive: {
				backgroundColor:
					colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[5],
			},
			navLinkInactive: {
				"&:hover": {
					backgroundColor:
						colorScheme === "dark"
							? theme.colors.dark[6]
							: theme.colors.gray[1],
				},
			},
			header: {
				display: "flex",
				alignItems: "center",
				height: "100%",
				// marginBottom: "2%",
			},
			headerRightItems: {
				marginLeft: "auto",
			},

			mediaQuery: {
				display: "none",
			},
			footer: {
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
			},
		};
	});
	const { classes } = getThemeStyles();
	return classes;
};
