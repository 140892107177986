import { useState, useRef, useContext, useImperativeHandle } from "react";
import {
	useMantineColorScheme,
	ColorScheme,
	Tabs,
	Divider,
} from "@mantine/core";
import { createStyles } from "@mantine/styles";
import { getCostantStyles } from "@/Costants/costantStyles";
import { useTranslation } from "react-i18next";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableTab from "../DraggableTab";

import { languageOptions } from "@/Costants/languageOptions";
import GetCodeFileIcon from "@/Components/GetCodeFileIcon";
import { FileManagerContext } from "@/Components/EditorScreen/FileManagerContext";
import { forwardRef } from "react";
import { iFile } from "@/Types/FileManager";
import { TemplateModalHandle } from "../../Modals/TemplateModal";
import NewFileFormModal from "../../Modals/NewFileFormModal";
import EditFilenameModal, {
	EditFilenameModalHandle,
} from "../../Modals/EditFilenameModal";
import TabsDeleteFileIcon from "./TabsDeleteFileIcon";
import TabsNewFileIcon from "./TabsNewFileIcon";
import TabsEditFileIcon from "./TabsEditFileIcon";
import TabsDownloadFileIcon from "./TabsDownloadFileIcon";
import DeleteFileModal from "../../Modals/DeleteFileModal";
import specialFileKeys from "@/Costants/specialFileKeys";
import TabsSaveFileIcon from "./TabsSaveFileIcon";
import { showInfoToast } from "@/Functions/toastNotifications";
import Cookies from "js-cookie";
import { cookiesPaths } from "@/Costants/cookiesPaths";
import TabsCompileFileIcon from "./TabsCompileFileIcon";

interface iTab {
	label: string;
	fileExtension: string;
	langId: number;
	path: string;
	fileKey: string;
}

interface iEditorTabs {
	handleFileDownload: (activeFileKey: string) => void;
	activeFileKey: string;
	onFileDelete: (fileKey: string) => void;
	onFileEditEnter: (
		fileKey: string,
		newName?: string,
		newPath?: string,
		newLangId?: number
	) => void;
}

const EditorTabsIcons = ({
	handleFileDownload,
	activeFileKey,
	onFileDelete,
	onFileEditEnter,
}: iEditorTabs) => {
	const { t } = useTranslation();

	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const classes = getStyles(colorScheme);
	const costantStyles = getCostantStyles(colorScheme);

	const { fileManager, fileManagerTools } = useContext(FileManagerContext);

	const newFileRef = useRef<TemplateModalHandle>(null);
	const editFilenameRef = useRef<EditFilenameModalHandle>(null);
	const deleteFileRef = useRef<TemplateModalHandle>(null);

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				paddingRight: "1%",
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "flex-end",
					marginRight: "2%",
				}}
			>
				{activeFileKey !== specialFileKeys.welcomeFileKey && (
					<div
						style={{
							display: "flex",
							flexDirection: "row",
						}}
					>
						<EditFilenameModal
							onFileEditEnter={(fileKey, newName?, newPath?, newLangId?) => {
								const file = fileManager[fileKey];
								onFileEditEnter(fileKey, newName, newPath, newLangId);
								fileManagerTools.editFile(fileKey, newName, newPath, newLangId);
								editFilenameRef.current?.refreshFileStats(
									newName ? newName : file.name,
									newPath ? newPath : file.path,
									newLangId != undefined ? newLangId : file.langId
								);
							}}
							ref={editFilenameRef}
							fileKey={activeFileKey}
						/>
						<DeleteFileModal
							ref={deleteFileRef}
							activeFileKey={activeFileKey}
							onFileDelete={onFileDelete}
						/>
						<TabsEditFileIcon forwardRef={editFilenameRef} />
						<TabsDeleteFileIcon forwardRef={deleteFileRef} />
						<TabsSaveFileIcon
							onClick={() => {
								Cookies.set(
									cookiesPaths.savedFile,
									JSON.stringify(fileManager[activeFileKey]),
									{
										expires: 365000,
										sameSite: "lax",
										path: "/",
									}
								);
								showInfoToast("File saved in your browser cache!");
							}}
						/>
					</div>
				)}
				<NewFileFormModal
					ref={newFileRef}
					defaultPath={activeFileKey as string}
				/>
				<TabsNewFileIcon forwardRef={newFileRef} />
				<TabsDownloadFileIcon
					onClick={() => {
						handleFileDownload(activeFileKey as string);
					}}
				/>
			</div>
			<Divider
				orientation="vertical"
				size="sm"
				style={{
					marginRight: "2%",
				}}
			/>
			{activeFileKey !== specialFileKeys.welcomeFileKey && (
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "flex-start",
					}}
				>
					<TabsCompileFileIcon
						onClick={() => {
							fileManagerTools.compileFile();
						}}
					/>
				</div>
			)}
		</div>
	);
};

export default EditorTabsIcons;

const getStyles = (colorScheme: ColorScheme) => {
	const getThemeStyles = createStyles((theme) => {
		return {
			tab: {
				borderColor:
					colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.dark[2],
				borderWidth: "1px",
			},
			actionIcon: { height: "100%", backgroundColor: "transparent" },
		};
	});
	const { classes } = getThemeStyles();
	return classes;
};
