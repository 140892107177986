import React from "react";
import {
	useMantineColorScheme,
	ColorScheme,
	Tooltip,
	ActionIcon,
} from "@mantine/core";
import { createStyles } from "@mantine/styles";
import { getCostantStyles } from "@/Costants/costantStyles";
import { useTranslation } from "react-i18next";
import { IconType } from "react-icons";

interface iTabsIconFactory {
	forwardRef?: React.RefObject<any>;
	onClick?: () => void;
	tooltip: string;
	Icon: IconType;
	size?: number;
}

export interface TabsIconFactoryChild {
	forwardRef?: React.RefObject<any>;
	onClick?: () => void;
}

const TabsIconFactory = ({
	forwardRef,
	tooltip,
	Icon,
	onClick,
	size,
}: iTabsIconFactory) => {
	const { t } = useTranslation();
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const classes = getStyles(colorScheme);
	const costantStyles = getCostantStyles(colorScheme);

	return (
		<Tooltip label={tooltip} withArrow>
			<ActionIcon
				className={classes.actionIcon}
				style={{
					backgroundColor: "transparent",
					width: size || 30,
				}}
				onClick={() => {
					if (forwardRef) {
						if (forwardRef.current) {
							forwardRef.current.open();
						}
					}
					if (onClick) {
						onClick();
					}
				}}
			>
				<Icon size={size || 30} />
			</ActionIcon>
		</Tooltip>
	);
};

export default TabsIconFactory;

const getStyles = (colorScheme: ColorScheme) => {
	const getThemeStyles = createStyles((theme) => {
		const color =
			colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2];

		return { actionIcon: { height: "100%", backgroundColor: "transparent" } };
	});
	const { classes } = getThemeStyles();
	return classes;
};
