import React from "react";
import { Select } from "@mantine/core";
import { monacoThemes } from "@/lib/defineTheme";
import { useTranslation } from "react-i18next";

const ThemeDropdown = ({
	handleThemeChange,
	theme,
}: {
	handleThemeChange: (theme: string) => void;
	theme: string;
}) => {
	const { t } = useTranslation();

	return (
		<Select
			placeholder={`${t("dropdown.theme")}...`}
			data={Object.entries(monacoThemes).map(([themeId, themeName]) => ({
				label: themeName,
				value: themeId,
				key: themeId,
			}))}
			value={theme}
			onChange={handleThemeChange}
			label={t("dropdown.theme")}
			searchable
		/>
	);
};

export default ThemeDropdown;
