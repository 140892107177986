import { useState, useRef, useContext, useImperativeHandle } from "react";
import { useMantineColorScheme, ColorScheme, Tabs } from "@mantine/core";
import { createStyles } from "@mantine/styles";
import { getCostantStyles } from "@/Costants/costantStyles";
import { useTranslation } from "react-i18next";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableTab from "./DraggableTab";

import { languageOptions } from "@/Costants/languageOptions";
import GetCodeFileIcon from "@/Components/GetCodeFileIcon";
import { FileManagerContext } from "@/Components/EditorScreen/FileManagerContext";
import { forwardRef } from "react";
import { iFile } from "@/Types/FileManager";
import { TemplateModalHandle } from "../Modals/TemplateModal";
import { EditFilenameModalHandle } from "../Modals/EditFilenameModal";
import specialFileKeys from "@/Costants/specialFileKeys";
import EditorTabsIcons from "./EditorTabsIcons";

interface iTab {
	label: string;
	fileExtension: string;
	langId: number;
	path: string;
	fileKey: string;
}

interface iEditorTabs {
	initTabs: iTab[];
	onTabChange: (tab: iTab | null) => void;
	handleFileDownload: (activeFileKey: string) => void;
	activeFileKey: string;
}

export type EditorTabsHandle = {
	openFile: (file: iFile) => void;
	isTabOpen: (fileKey: string) => boolean;
};

const EditorTabs = forwardRef<EditorTabsHandle, iEditorTabs>(
	({ initTabs, onTabChange, handleFileDownload, activeFileKey }, ref) => {
		const { t } = useTranslation();

		const { colorScheme, toggleColorScheme } = useMantineColorScheme();
		const classes = getStyles(colorScheme);
		const costantStyles = getCostantStyles(colorScheme);
		const [tabsData, setTabsData] = useState<iTab[]>(initTabs);

		const { fileManager, fileManagerTools } = useContext(FileManagerContext);

		const newFileRef = useRef<TemplateModalHandle>(null);
		const editFilenameRef = useRef<EditFilenameModalHandle>(null);
		const deleteFileRef = useRef<TemplateModalHandle>(null);

		const changeTab = (activeTab: iTab | null) => {
			//open welcome tab
			if (activeTab === null) {
				const file = fileManager[specialFileKeys.welcomeFileKey];
				const welcomeTab = {
					label: file.name,
					fileExtension: file.fileExtension,
					langId: file.langId,
					path: file.path,
					fileKey: file.fileKey,
				};
				const newTabs = [welcomeTab];
				setTabsData(newTabs);
				onTabChange(welcomeTab);
				return;
			}
			onTabChange(activeTab);
		};

		useImperativeHandle(ref, () => ({
			//open tab if user clicks on file name
			openFile(file) {
				const { name, fileExtension, langId, fileKey } = file;

				const tabPresent = tabsData.find((tab) => tab.path === file.path);
				if (tabPresent) {
					changeTab(tabPresent);
					return;
				}

				const newTabs = [...tabsData];

				const tab: iTab = {
					label: name,
					fileExtension,
					langId: file.langId,
					path: file.path,
					fileKey: file.fileKey,
				};
				newTabs.push(tab);

				setTabsData(newTabs);
				changeTab(tab);
			},
			isTabOpen(fileKey: string) {
				return fileKey === activeFileKey;
			},
		}));

		const onTabSelect = (active: number) => {
			changeTab(tabsData[active]);
		};

		const moveTab = (dragIndex: number, hoverIndex: number) => {
			const tabs = [...tabsData];
			const dragTab = tabs[dragIndex];
			tabs.splice(dragIndex, 1);
			tabs.splice(hoverIndex, 0, dragTab);
			setTabsData(tabs);
		};

		const onTabClose = (index: number) => {
			const tabs = [...tabsData];

			tabs.splice(index, 1);
			setTabsData(tabs);

			if (tabs.length === 0) {
				changeTab(null);
				return;
			}
			changeTab(tabs[tabs.length - 1]);
		};

		return (
			<DndProvider backend={HTML5Backend}>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						width: "100%",
					}}
				>
					{tabsData.length > 0 && (
						<div
							style={{
								flex: 1,
							}}
						>
							<Tabs variant="outline">
								{tabsData.map((tab, index) => {
									return (
										<DraggableTab
											key={index}
											fileKey={tab.fileKey}
											index={index}
											tabsLength={tabsData.length}
											label={`${tab.label}.${tab.fileExtension}`}
											icon={
												<GetCodeFileIcon fileExtension={tab.fileExtension} />
											}
											moveTab={moveTab}
											onTabChange={onTabSelect}
											onTabClose={onTabClose}
										/>
									);
								})}
							</Tabs>
						</div>
					)}
					<EditorTabsIcons
						handleFileDownload={handleFileDownload}
						activeFileKey={activeFileKey}
						onFileDelete={(fileKey) => {
							const newTabs = [...tabsData];
							const tab = newTabs.find((tab) => tab.fileKey === fileKey);
							onTabClose(newTabs.indexOf(tab as iTab));
							fileManagerTools.deleteFile(fileKey);
						}}
						onFileEditEnter={(fileKey, newName?, newPath?, newLangId?) => {
							const newTabs = [...tabsData];
							const tab = newTabs.find((tab) => tab.fileKey === fileKey);
							if (tab) {
								tab.label = newName ? newName : tab.label;
								tab.path = newPath ? newPath : tab.path;
								tab.langId = newLangId != undefined ? newLangId : tab.langId;

								tab.fileExtension =
									newLangId != undefined
										? languageOptions[newLangId].fileExtension
										: tab.fileExtension;

								setTabsData(newTabs);
							}
						}}
					/>
				</div>
			</DndProvider>
		);
	}
);

export default EditorTabs;

const getStyles = (colorScheme: ColorScheme) => {
	const getThemeStyles = createStyles((theme) => {
		return {
			tab: {
				borderColor:
					colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.dark[2],
				borderWidth: "1px",
			},
			actionIcon: { height: "100%", backgroundColor: "transparent" },
		};
	});
	const { classes } = getThemeStyles();
	return classes;
};
