export const handleDownload = (
	str: string,
	fileExtension: string,
	fileName = "onlineEditorCode"
) => {
	const element = document.createElement("a");
	const file = new Blob([str], {
		type: "text/plain",
	});
	element.href = URL.createObjectURL(file);
	element.download = `${fileName}.${fileExtension}`;
	document.body.appendChild(element);
	element.click();
};
