import { loader } from "@monaco-editor/react";
import libMonacoThemes from "monaco-themes/themes/themelist.json";
export const monacoThemes: {
	[key: string]: string;
} = {
	...libMonacoThemes,
	light: "light",
	"vs-dark": "vs-dark",
};

const defineTheme = async (theme: string) => {
	return new Promise((res: any) => {
		Promise.all([
			loader.init(),
			import(`monaco-themes/themes/${monacoThemes[theme]}.json`),
		])
			.then(([monaco, themeData]) => {
				monaco.editor.defineTheme(theme, themeData);
				res();
			})
			.catch((err) => {
				// console.log(err);
			});
	});
};

export { defineTheme };
