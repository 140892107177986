import React from "react";
import {
	useMantineColorScheme,
	ColorScheme,
	ActionIcon,
	Tooltip,
} from "@mantine/core";
import { createStyles, useMantineTheme } from "@mantine/styles";
import { getCostantStyles } from "@/Costants/costantStyles";
import { useTranslation } from "react-i18next";
import { IconType } from "react-icons";

interface iSidebarIcon {
	Icon: IconType;
	onClick: () => void;
	label: string;
	isActive: boolean;
}
const ICONS_SIZE = 40;
const SidebarIcon = ({ Icon, onClick, label, isActive }: iSidebarIcon) => {
	const { t } = useTranslation();
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const theme = useMantineTheme();
	const classes = getStyles(colorScheme);
	const costantStyles = getCostantStyles(colorScheme);

	const iconActiveBackgroundColor =
		colorScheme === "dark" ? theme.colors.gray[8] : theme.colors.gray[4];

	return (
		<Tooltip label={label} position="right" withArrow>
			<ActionIcon
				size={ICONS_SIZE}
				variant="hover"
				onClick={onClick}
				style={{
					marginBottom: `${ICONS_SIZE * 0.1}px`,
				}}
			>
				<Icon
					size={ICONS_SIZE}
					color={
						colorScheme === "dark" ? theme.colors.gray[4] : theme.colors.dark[9]
					}
					style={{
						backgroundColor: isActive
							? iconActiveBackgroundColor
							: "transparent",
						borderRadius: 8,
						padding: 2,
					}}
				/>
			</ActionIcon>
		</Tooltip>
	);
};

export default SidebarIcon;

const getStyles = (colorScheme: ColorScheme) => {
	const getThemeStyles = createStyles((theme) => {
		const color =
			colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2];

		return {};
	});
	const { classes } = getThemeStyles();
	return classes;
};
